import React from "react";
import {
  Export,
  Item,
  Toolbar,
  ColumnChooser,
  Column,Lookup,
  Button,
  DataGrid,
  FilterRow,
  SearchPanel,
  HeaderFilter,
  Paging,
} from "devextreme-react/data-grid";
import { Button as DxButton } from "devextreme-react/button";
import { useNavigate } from "react-router-dom";
import { fosTacAnalysisDataStore ,digesterDataStore} from "../../api/dxDataStores";
import { handleDxExcelExport} from "../../utils/dxExcelExportUtils"; // Dosya yolu doğru ise ayarlayın

export default function FosTacAnalysis() {
  const navigate = useNavigate();
  function onExporting(e) {
    handleDxExcelExport(e, "FosTacAnalysis", "FosTacAnalysis.xlsx");
  }
 
  const onEditClick = (e) => {
    navigate(`/qualityControl/fosTacAnalysis/form/${e.row.data.id}`);
  };
  const onDeleteClick = (e) => {
    console.log("Silme İşlemi");
  };
  const onAddClick = (e) => {
    navigate(`/qualityControl/fosTacAnalysis/form/${"00000000-0000-0000-0000-000000000000"}`);
  };
  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <h2 className={"content-block"}>FosTac Analysis</h2>
          <DataGrid
            dataSource={fosTacAnalysisDataStore}
            showBorders={true}
            height={700}
            remoteOperations={true}
            onExporting={onExporting}
            columnAutoWidth={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
          >
            <Toolbar>
              <Item location="after" locateInMenu="auto">
                <DxButton icon="plus" onClick={onAddClick} />
              </Item>
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="exportButton" locateInMenu="auto" />
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="columnChooserButton" locateInMenu="auto" />
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="searchPanel" location="after" />
            </Toolbar>
            <ColumnChooser enabled />
            <SearchPanel
              visible={true}
              highlightCaseSensitive={true}
              placeholder="Search"
              width={250}
            />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Paging defaultPageSize={15} />
            <Column type="buttons">
              <Button hint="Edit" icon="edit" onClick={onEditClick} />
              <Button hint="Delete" icon="trash" onClick={onDeleteClick} />
            </Column>
            <Column
                dataField="digesterId"
                caption="Digester ID"
              >
                <Lookup
                  dataSource={digesterDataStore}
                  valueExpr="id"
                  displayExpr="name"
                />
              </Column>
              <Column
              dataField="entryTime"
              caption="Entry Time"
              dataType="datetime"
              format="dd.MM.yyyy HH:mm"
            />
            <Column
              dataField="ph"
              caption="PH"
              dataType="text"          
            />
            <Column
              dataField="ec"
              caption="EC"
              dataType="number"
              format={"##0.####"}
            />
            <Column
              dataField="t"
              caption="T"
              dataType="number"
              format={"##0.####"}
            />
            <Column
              dataField="fos"
              caption="Fos"
              dataType="number"
              format={"##0.####"}
            />
           <Column
              dataField="tac"
              caption="Tac"
              dataType="number"
              format={"##0.####"}
            />
            <Export enabled={true} allowExportSelectedData={true} />
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
  );
}
