import React from "react";
export default function HomeWidget({
  subject,
  priority,
  priorityStr,
  date,
  assignee,
  onEdit,
}) {
  return (
    <div className={"kanban-card dx-card"}>
      <div className={`card-wrapper priority-${priority}`}>
        <div className={"card-priority"}></div>
        {onEdit && (
          <div
            className={
              "dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-icon edit-button"
            }
            role="button"
            aria-label="edit"
            onClick={onEdit}
          >
            <div className={"dx-button-content"}>
              <i className={"dx-icon dx-icon-edit"}></i>
            </div>
          </div>
        )}
        <div className={"card-content"}>
          <div className={"card-subject dx-theme-text-color"}>{subject}</div>
          <div className={"card-data"}>
            {priorityStr && <span className={"priority"}>{priorityStr}</span>}
            {assignee && (
              <span className={"date dx-theme-text-color"}>{date}</span>
            )}
          </div>
          {assignee && (
            <div className={"card-assignee"}>
              <span className={"dx-theme-text-color"}>{assignee}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
