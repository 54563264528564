import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import LoadIndicator from "devextreme-react/load-indicator";
import Form, {
  GroupItem,
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import {
  save,
  getById,
} from "../../../api/liquidFermentedFertilizerWeightbridgeData";

export default function LiquidFermentedFertilizerWeightbridgeDataForm() {
  const { id } = useParams();
  const isEmptyGuid = (guid) => guid === "00000000-0000-0000-0000-000000000000";

  const [formData, setFormData] = useState({
    id: id ?? "",
    entryDate: new Date(),
    tonnage: 0,
  });

  const [loading, setLoading] = useState(false);
  const isIdEmpty = isEmptyGuid(id);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getById(id);

      if (result.isOk) {
        setFormData(result.data);
      } else {
        notify(result.message, "error", 2000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (!isIdEmpty) {
      fetchData();
    }
  }, [fetchData, isIdEmpty]);

  const handleChange = (fieldName) => (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: e.value,
    }));
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { entryDate, tonnage } = formData;

      try {
        setLoading(true);
        const result = await save(id, entryDate, tonnage);

        if (!result.isOk) {
          notify(result.message, "error", 2000);
        } else {
          notify(result.message, "success", 2000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setLoading(false);
      }
    },
    [id, formData]
  );

  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <h2 className={"content-block"}>
            Liquid Fermented Fertilizer Weightbridge Data{" "}
            {isIdEmpty ? "Save" : "Update"} Form
          </h2>
          <form className={"login-form"} onSubmit={onSubmit}>
            <Form formData={formData} disabled={loading}>
              <GroupItem colCount={2}>
                <Item
                  dataField={"entryDate"}
                  editorType={"dxDateBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Entry Date",
                    type: "datetime",
                    displayFormat: "dd.MM.yyyy HH:mm",
                    onValueChanged: handleChange("entryDate"),
                  }}
                >
                  <RequiredRule message="Entry Date is Required." />
                  <Label visible={true} text="Entry Date" />
                </Item>           
                <Item
                  dataField={"tonnage"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Tonnage",
                    format: "##0.####",
                    onValueChanged: handleChange("tonnage"),
                  }}
                >
                  <RequiredRule message="Tonnage is Required." />
                  <Label visible={true} text="Tonnage" />
                </Item>
              </GroupItem>
              <ButtonItem>
                <ButtonOptions
                  width={"100%"}
                  type="normal"
                  stylingMode="outlined"
                  useSubmitBehavior={true}
                >
                  <span className="dx-button-text">
                    {loading ? (
                      <LoadIndicator
                        width={"24px"}
                        height={"24px"}
                        visible={true}
                      />
                    ) : (
                      "Kaydet"
                    )}
                  </span>
                </ButtonOptions>
              </ButtonItem>
            </Form>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}
