import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { setupListeners } from "@reduxjs/toolkit/query";
import { store } from "./configure/app/store";
import axios from 'axios'
import * as _setupAxios from './configure/setup/setupAxios';

setupListeners(store.dispatch);
_setupAxios.default(axios, store);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>      
      <App />
    </Provider>
  </React.StrictMode>
);
