import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import LoadIndicator from "devextreme-react/load-indicator";
import Form, {
  GroupItem,
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from "devextreme-react/form";
import { useDispatch } from "react-redux";
import notify from "devextreme/ui/notify";
import { save, getById } from "../../../api/liquidWasteInput";
import { useSelector } from "react-redux";

export default function LiquidWasteInputForm() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isEmptyGuid = (guid) => guid === "00000000-0000-0000-0000-000000000000";

  const [formData, setFormData] = useState({
    id: id ?? "",
    farmId: "",
    farmLivestockTypeId: "",
    entryTime: new Date(),
    tonnage: 0,
    dryMatter: 0,
    organicDryMatter: 0,
    ph: 0,
    ec: 0,
  });

  const [loading, setLoading] = useState(false);
  const isIdEmpty = isEmptyGuid(id);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getById(id);

      if (result.isOk) {
        setFormData(result.data);
      } else {
        notify(result.message, "error", 2000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (!isIdEmpty) {
      fetchData();
    }
  }, [fetchData, isIdEmpty]);

  const handleChange = (fieldName) => (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: e.value,
    }));
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const {
        farmId,
        farmLivestockTypeId,
        entryTime,
        tonnage,
        dryMatter,
        organicDryMatter,
        ph,
        ec,
      } = formData;

      try {
        setLoading(true);
        const result = await save(
          id,
          farmId,
          farmLivestockTypeId,
          entryTime,
          tonnage,
          dryMatter,
          organicDryMatter,
          ph,
          ec
        );

        if (!result.isOk) {
          notify(result.message, "error", 2000);
        } else {
          notify(result.message, "success", 2000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setLoading(false);
      }
    },
    [id, formData, dispatch]
  );

  const farmSelectBoxList = useSelector(
    (state) => state.selectBoxLists.farmSelectBoxList
  );
  const farmLivestockTypeSelectBoxList = useSelector(
    (state) => state.selectBoxLists.farmLivestockTypeSelectBoxList
  );
  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <h2 className={"content-block"}>
            Liquid Waste Input {isIdEmpty ? "Save" : "Update"} Form
          </h2>
          <form className={"login-form"} onSubmit={onSubmit}>
            <Form formData={formData} disabled={loading}>
              <GroupItem colCount={2}>
                <Item
                  dataField={"farmId"}
                  editorType={"dxSelectBox"}
                  editorOptions={{
                    items: farmSelectBoxList,
                    displayExpr: "text",
                    valueExpr: "value",
                    stylingMode: "filled",
                    searchEnabled: true,
                    onValueChanged: handleChange("farmId"),
                  }}
                >
                  <RequiredRule message="Farm is Required." />
                  <Label visible={true} text="Farm" />
                </Item>
                <Item
                  dataField={"farmLivestockTypeId"}
                  editorType={"dxSelectBox"}
                  editorOptions={{
                    items: farmLivestockTypeSelectBoxList,
                    displayExpr: "text",
                    valueExpr: "value",
                    stylingMode: "filled",
                    searchEnabled: true,
                    onValueChanged: handleChange("farmLivestockTypeId"),
                  }}
                >
                  <RequiredRule message="Farm Livestock Type is Required." />
                  <Label visible={true} text="Farm Livestock Type" />
                </Item>
              </GroupItem>
              <GroupItem colCount={2}>
                <Item
                  dataField={"entryTime"}
                  editorType={"dxDateBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Entry Time",
                    type: "datetime",
                    displayFormat: "dd.MM.yyyy HH:mm",
                    onValueChanged: handleChange("entryTime"),
                  }}
                >
                  <RequiredRule message="Entry Time is Required." />
                  <Label visible={true} text="Entry Time" />
                </Item>
              </GroupItem>
              <GroupItem colCount={2}>
                <Item
                  dataField={"tonnage"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Tonnage",
                    onValueChanged: handleChange("tonnage"),
                  }}
                >
                  <RequiredRule message="Tonnage is Required." />
                  <Label visible={true} text="Tonnage" />
                </Item>
              </GroupItem>
              <GroupItem colCount={2}>
                <Item
                  dataField={"dryMatter"}
                  editorType={"dxTextBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Dry Matter",
                    onValueChanged: handleChange("dryMatter"),
                  }}
                >
                  <RequiredRule message="Dry Matter is Required." />
                  <Label visible={true} text="Dry Matter" />
                </Item>
                <Item
                  dataField={"organicDryMatter"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Organic Dry Matter",
                    onValueChanged: handleChange("organicDryMatter"),
                  }}
                >
                  <RequiredRule message="Organic Dry Matter is Required." />
                  <Label visible={true} text="Organic Dry Matter" />
                </Item>
              </GroupItem>
              <GroupItem colCount={2}>
                <Item
                  dataField={"ph"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "PH",
                    onValueChanged: handleChange("ph"),
                  }}
                >
                  <RequiredRule message="PH is Required." />
                  <Label visible={true} text="PH" />
                </Item>
                <Item
                  dataField={"ec"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "EC",
                    onValueChanged: handleChange("ec"),
                  }}
                >
                  <RequiredRule message="EC is Required." />
                  <Label visible={true} text="EC" />
                </Item>
              </GroupItem>
              <ButtonItem>
                <ButtonOptions
                  width={"100%"}
                  type="normal"
                  stylingMode="outlined"
                  useSubmitBehavior={true}
                >
                  <span className="dx-button-text">
                    {loading ? (
                      <LoadIndicator
                        width={"24px"}
                        height={"24px"}
                        visible={true}
                      />
                    ) : (
                      "Kaydet"
                    )}
                  </span>
                </ButtonOptions>
              </ButtonItem>
            </Form>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}
