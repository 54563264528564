import { createStore } from "devextreme-aspnet-data-nojquery";
import { RootState} from '../configure/app/store';

// Token'ı al
const token = RootState().auth.token.token;
export const biogasPlantSurveyDataStore = createStore({
  key: "id",
  loadUrl: `${process.env.REACT_APP_API_URL}/biogasPlantSurvey/getdxlist`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = { Authorization: `Bearer ${RootState().auth.token.token}` };
  },
});

export const biogasPlantInfoDataStore = createStore({
  key: "id",
  loadUrl: `${process.env.REACT_APP_API_URL}/biogasPlantInfo/getdxlist`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = { Authorization: `Bearer ${RootState().auth.token.token}` };
  },
});

export const yearlyCarbonEmissionsDataStore = createStore({
  key: "id",
  loadUrl: `${process.env.REACT_APP_API_URL}/yearlyCarbonEmissions/getdxlist`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = { Authorization: `Bearer ${RootState().auth.token.token}` };
  },
});
export const farmLivestockTypeDataStore = createStore({
  key: "id",
  loadUrl: `${process.env.REACT_APP_API_URL}/farmLivestockType/getdxlist`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = { Authorization: `Bearer ${RootState().auth.token.token}` };
  },
});

export const farmDataStore = createStore({
  key: "id",
  loadUrl: `${process.env.REACT_APP_API_URL}/farm/getdxlist`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = { Authorization: `Bearer ${RootState().auth.token.token}` };
  },
});
export  const weighbridgeDataDataStore = createStore({
  key: "id",
  loadUrl: `${process.env.REACT_APP_API_URL}/weighbridgeData/getdxlist`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = { Authorization: `Bearer ${RootState().auth.token.token}` };
  },
});
export  const userDataStore = createStore({
  key: "id",
  loadUrl: `${process.env.REACT_APP_API_URL}/user/getdxlist`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = { Authorization: `Bearer ${RootState().auth.token.token}` };
  },
});

export  const farmSurveyDataStore = createStore({
  key: "id",
  loadUrl: `${process.env.REACT_APP_API_URL}/farmSurvey/getdxlist`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = { Authorization: `Bearer ${RootState().auth.token.token}` };
  },
});

export  const digesterDataStore = createStore({
  key: "id",
  loadUrl: `${process.env.REACT_APP_API_URL}/digester/getdxlist`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = { Authorization: `Bearer ${RootState().auth.token.token}` };
  },
});

export  const digesterDataDataStore = createStore({
  key: "id",
  loadUrl: `${process.env.REACT_APP_API_URL}/digesterData/getdxlist`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = { Authorization: `Bearer ${RootState().auth.token.token}` };
  },
});


export  const digesterLiquidWasteAnalysisDataStore = createStore({
  key: "id",
  loadUrl: `${process.env.REACT_APP_API_URL}/digesterLiquidWasteAnalysis/getdxlist`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = { Authorization: `Bearer ${RootState().auth.token.token}` };
  },
});

export  const fosTacAnalysisDataStore = createStore({
  key: "id",
  loadUrl: `${process.env.REACT_APP_API_URL}/fosTacAnalysis/getdxlist`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = { Authorization: `Bearer ${RootState().auth.token.token}` };
  },
});

export  const liquidWasteAnalysisDataStore = createStore({
  key: "id",
  loadUrl: `${process.env.REACT_APP_API_URL}/liquidWasteAnalysis/getdxlist`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = { Authorization: `Bearer ${RootState().auth.token.token}` };
  },
});

export  const liquidWasteInputDataStore = createStore({
  key: "id",
  loadUrl: `${process.env.REACT_APP_API_URL}/liquidWasteInput/getdxlist`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = { Authorization: `Bearer ${RootState().auth.token.token}` };
  },
});

export  const ososDataDataStore = createStore({
  key: "id",
  loadUrl: `${process.env.REACT_APP_API_URL}/ososData/getdxlist`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = { Authorization: `Bearer ${RootState().auth.token.token}` };
  },
});

export  const processReinforcementDataDataStore = createStore({
  key: "id",
  loadUrl: `${process.env.REACT_APP_API_URL}/processReinforcementData/getdxlist`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = { Authorization: `Bearer ${RootState().auth.token.token}` };
  },
});

export  const processReinforcementMaterialDataStore = createStore({
  key: "id",
  loadUrl: `${process.env.REACT_APP_API_URL}/processReinforcementMaterial/getdxlist`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = { Authorization: `Bearer ${RootState().auth.token.token}` };
  },
});

export  const solidWasteAnalysisDataStore = createStore({
  key: "id",
  loadUrl: `${process.env.REACT_APP_API_URL}/solidWasteAnalysis/getdxlist`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = { Authorization: `Bearer ${RootState().auth.token.token}` };
  },
});

export  const solidWasteInputDataStore = createStore({
  key: "id",
  loadUrl: `${process.env.REACT_APP_API_URL}/solidWasteInput/getdxlist`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = { Authorization: `Bearer ${RootState().auth.token.token}` };
  },
});

export  const liquidFermentedFertilizerWeightbridgeDataDataStore = createStore({
  key: "id",
  loadUrl: `${process.env.REACT_APP_API_URL}/liquidFermentedFertilizerWeightbridgeData/getdxlist`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.headers = { Authorization: `Bearer ${RootState().auth.token.token}` };
  },
});
