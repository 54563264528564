import React from "react";
import ScrollView from 'devextreme-react/scroll-view';
import FileUploader from 'devextreme-react/file-uploader';
export default function CompletedTask({
  yearlyCarbonEmissionsId,
  taskId,
  title,
  isCompleted,
  taskLinks,
}) {
  return (
    <React.Fragment>
      <div className="task" key={taskId}>
        <i className={`dx-icon-${isCompleted ? 'todo' : 'remove'} task-icon ${isCompleted ? 'completed' : ''}`}></i>
        <div className="task-body">
          <div className="task-title dx-theme-text-color">{title}</div>
          <ScrollView
            height={150}
            showScrollbar="onHover"
          >
            {
              isCompleted ?
                (<ul className="task-links">
                  {taskLinks.map((link, index) => (
                    <li className="task-link" key={index}>
                      <a href={link.href}>
                        <i className="dx-icon-doc pe-5"></i>
                        {link.text}
                      </a>
                    </li>
                  ))}
                </ul>) :
                (<FileUploader
                  multiple={true}
                  accept='*'
                  uploadMode='useButtons'              
                  uploadUrl={`${process.env.REACT_APP_API_URL}/YearlyCarbonEmissions/UploadFiles?id=${yearlyCarbonEmissionsId}&folderName=${taskId}`}
                  maxFileSize={4000000}
                />)
            }
          </ScrollView>
        </div>
      </div>
    </React.Fragment>
  );
}
