import React from "react";
import {
  Export,
  Item,
  Toolbar,
  ColumnChooser,
  Column,
  Button,
  DataGrid,
  Lookup,
  FilterRow,
  SearchPanel,
  HeaderFilter,
  Paging,
} from "devextreme-react/data-grid";
import { Button as DxButton } from "devextreme-react/button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {liquidFermentedFertilizerWeightbridgeDataDataStore} from '../../api/dxDataStores'
import { handleDxExcelExport } from "../../utils/dxExcelExportUtils"; // Dosya yolu doğru ise ayarlayın

export default function LiquidFermentedFertilizerWeightbridgeDatas() {
  const navigate = useNavigate();
  
  function onExporting(e) {
    handleDxExcelExport(e, "LiquidFermentedFertilizerWeightbridgeData", "LiquidFermentedFertilizerWeightbridgeDatas.xlsx");
  }

  const onEditClick = (e) => {
    navigate(`/logisticsManagement/liquidFermentedFertilizerWeightbridgeDatas/form/${e.row.data.id}`);
  };
  const onDeleteClick = (e) => {
    console.log("Silme İşlemi");
  };
  const onAddClick = (e) => {
    navigate(`/logisticsManagement/liquidFermentedFertilizerWeightbridgeDatas/form/${"00000000-0000-0000-0000-000000000000"}`);
  };

 
  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <h2 className={"content-block"}>Liquid Fermented Fertilizer Weightbridge Data</h2>
          <DataGrid
            dataSource={liquidFermentedFertilizerWeightbridgeDataDataStore}
            showBorders={true}
              height={700}
              remoteOperations={true}
              onExporting={onExporting}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
          >
            <Toolbar>
              <Item location="after" locateInMenu="auto">
                <DxButton icon="plus" onClick={onAddClick} />
              </Item>
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="exportButton" locateInMenu="auto" />
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="columnChooserButton" locateInMenu="auto" />
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="searchPanel" location="after" />
            </Toolbar>
            <ColumnChooser enabled />
            <SearchPanel
              visible={true}
              highlightCaseSensitive={true}
              placeholder="Search"
              width={250}
            />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Paging defaultPageSize={15} />
            <Column type="buttons">
              <Button hint="Edit" icon="edit" onClick={onEditClick} />
              <Button hint="Delete" icon="trash" onClick={onDeleteClick} />
            </Column>   
            <Column
              dataField="entryDate"
              caption="Entry Date"
              dataType="datetime"
              format="dd.MM.yyyy HH:mm"
            />
            <Column
              dataField="tonnage"
              caption="Tonnage"
              dataType="number"
              format={"##0.####"}
            />    
            <Export enabled={true} allowExportSelectedData={true} />
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
  );
}
