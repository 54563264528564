export const navigation = [
  {
    text: "Dashboard",
    path: "/home",
    icon: "home",
  },
  {
    text: "Raw Material Management",
    icon: "box",
    items: [
      {
        text: "Farms",
        path: "/rawMaterialManagement/farms",
      },
      {
        text: "Farm Livestock Types",
        path: "/rawMaterialManagement/farmLivestockTypes",
      },
    ],
  },
  {
    text: "Inventory Management",
    icon: "chart",
    items: [
      {
        text: "Liquid Waste Inputs",
        path: "/inventoryManagement/liquidWasteInputs",
      },
      {
        text: "Solid Waste Inputs",
        path: "/inventoryManagement/solidWasteInputs",
      },
    ],
  },
  {
    text: "Logistics Management",
    icon: "airplane",
    items: [
      {
        text: "Weighbridge Datas",
        path: "/logisticsManagement/weighbridgeDatas",
      },
      {
        text: "Fermented Fertilizer Data",
        path: "/logisticsManagement/liquidFermentedFertilizerWeightbridgeDatas",
      },
    ],
  },
  {
    text: "Process Management",
    icon: "clock",
    items: [
      {
        text: "Digester Datas",
        path: "/processManagement/digesterDatas",
      },
      {
        text: "Digesters",
        path: "/processManagement/digesters",
      },
      {
        text: "OSOS Datas",
        path: "/processManagement/ososDatas",
      },
      {
        text: "Process Reinforcement Datas",
        path: "/processManagement/processReinforcementDatas",
      },
      {
        text: "Process Reinforcement Materials",
        path: "/processManagement/processReinforcementMaterials",
      },
    ],
  },
  {
    text: "Quality Control",
    icon: "checklist",
    items: [
      {
        text: "Digester Liquid Waste Analysis",
        path: "/qualityControl/digesterLiquidWasteAnalysis",
      },
      {
        text: "FosTac Analysis",
        path: "/qualityControl/fosTacAnalysis",
      },

      {
        text: "Liquid Waste Analysis",
        path: "/qualityControl/liquidWasteAnalysis",
      },

      {
        text: "Solid Waste Analysis",
        path: "/qualityControl/solidWasteAnalysis",
      },
    ],
  },
  {
    text: "Carbon Emission Tracking",
    icon: "datafield",
    items: [
      {
        text: "Yearly Carbon Emissions",
        path: "/carbonEmissionTracking/yearlyCarbonEmissions",
      },
      {
        text: "Biogas Plant Infos",
        path: "/carbonEmissionTracking/biogasPlantInfos",
      },

      {
        text: "Biogas Plant Surveys",
        path: "/carbonEmissionTracking/biogasPlantSurveys",
      },
      {
        text: "Farm Surveys",
        path: "/carbonEmissionTracking/farmSurveys",
      },
      {
        text: "BEyAMCalc",
        path: "/carbonEmissionTracking/bEyAMCalc",
      },
    ],
  },
  {
    text: "Preferences",
    icon: "preferences",
    items: [
      {
        text: "Users",
        path: "/preferences/users",
      },
    ],
  },
];
