import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";

export function handleDxExcelExport(e, worksheetName, fileName) {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet(worksheetName || "BiogasPlantSurvey");

  exportDataGrid({
    component: e.component,
    worksheet: worksheet,
    autoFilterEnabled: true,
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        fileName || "BiogasPlantSurveys.xlsx"
      );
    });
  });
  e.cancel = true;
}