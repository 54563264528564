import axios from "axios";

export async function save(
  id,
  name,
  address,
  tel,
  distance,
  coordinateX,
  coordinateY,
  bbpWasteDisposalMethod,
  bbpStorageTime,
  bbpManureTransportDistance
) {
  try {
    var res = await axios.post(`${process.env.REACT_APP_API_URL}/farm/save`, {
      id,
      name,
      address,
      tel,
      distance,
      coordinateX,
      coordinateY,
      bbpWasteDisposalMethod,
      bbpStorageTime,
      bbpManureTransportDistance,
    });

    return {
      isOk: true,
      data: res.data.data,
      message:res.data.message
    };
  } catch (error) {
    console.log(res);
    return {
      isOk: false,
      message: "Kayıt işlemi başarısız.",
    };
  }
}

export async function getById(id) {
  try {
    var res = await axios.get(
      `${process.env.REACT_APP_API_URL}/farm/getbyid?id=${id}`
    );

    return {
      isOk: true,
      data: res.data,
    };
  } catch (error) {
    return {
      isOk: false,
      message: "İşlem başarısız.",
    };
  }
}

export async function getSelectBoxList() {
  try {
    var res = await axios.get(
      `${process.env.REACT_APP_API_URL}/farm/getSelectBoxList`
    );

    return {
      isOk: true,
      data: res.data,
    };
  } catch (error) {
    return {
      isOk: false,
      message: "İşlem başarısız.",
    };
  }
}
