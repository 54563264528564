import React from "react";
import { useParams } from "react-router-dom";
import UserSaveWizard from "./userSaveWizard";
export default function UserForm() {
  const { id } = useParams();
  const isEmptyGuid = (guid) => {
    return guid === "00000000-0000-0000-0000-000000000000";
  };
  const isIdEmpty = isEmptyGuid(id);
  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <h2 className={"content-block"}>
            Kullanıcı {isIdEmpty ? "Kayıt" : "Düzenleme"}
            {id}
          </h2>
          <UserSaveWizard />
        </div>
      </div>
    </React.Fragment>
  );
}
