import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import LoadIndicator from "devextreme-react/load-indicator";
import Form, {
  GroupItem,
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from "devextreme-react/form";
import { useDispatch } from "react-redux";
import notify from "devextreme/ui/notify";
import { save, getById, getSelectBoxList } from "../../../api/farm";
import { setFarmSelectBoxList } from "../../../configure/slices/selectBoxLists-slice";

export default function FarmForm() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isEmptyGuid = (guid) => guid === "00000000-0000-0000-0000-000000000000";

  const [formData, setFormData] = useState({
    id: id ?? "",
    name: "",
    address: "",
    tel: "",
    distance: 0,
    coordinateX: "",
    coordinateY: "",
    bbpWasteDisposalMethod: "",
    bbpStorageTime: 0,
    bbpManureTransportDistance: 0,
  });

  const [loading, setLoading] = useState(false);
  const isIdEmpty = isEmptyGuid(id);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getById(id);

      if (result.isOk) {
        setFormData(result.data);
      } else {
        notify(result.message, "error", 2000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (!isIdEmpty) {
      fetchData();
    }
  }, [fetchData, isIdEmpty]);

  const handleChange = (fieldName) => (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: e.value,
    }));
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const {
        name,
        address,
        tel,
        distance,
        coordinateX,
        coordinateY,
        bbpWasteDisposalMethod,
        bbpStorageTime,
        bbpManureTransportDistance,
      } = formData;

      try {
        setLoading(true);
        const result = await save(
          id,
          name,
          address,
          tel,
          distance,
          coordinateX,
          coordinateY,
          bbpWasteDisposalMethod,
          bbpStorageTime,
          bbpManureTransportDistance
        );

        if (!result.isOk) {
          notify(result.message, "error", 2000);
        } else {
          notify(result.message, "success", 2000);
          const farmSelectBoxList = await getSelectBoxList();
          if (farmSelectBoxList.isOk)
            dispatch(setFarmSelectBoxList(farmSelectBoxList.data));
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setLoading(false);
      }
    },
    [id, formData,dispatch]
  );

  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <h2 className={"content-block"}>
            Farm {isIdEmpty ? "Save" : "Update"} Form
          </h2>
          <form className={"login-form"} onSubmit={onSubmit}>
            <Form formData={formData} disabled={loading}>
              <GroupItem colCount={2}>
                <Item
                  dataField={"name"}
                  editorType={"dxTextBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Name",
                    onValueChanged: handleChange("name"),
                  }}
                >
                  <RequiredRule message="Name is Required." />
                  <Label visible={true} text="Name" />
                </Item>
              </GroupItem>
              <GroupItem>
                <Item
                  dataField={"address"}
                  editorType={"dxTextBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Address",
                    onValueChanged: handleChange("address"),
                  }}
                >
                  <RequiredRule message="Address is Required." />
                  <Label visible={true} text="Address" />
                </Item>
              </GroupItem>
              <GroupItem colCount={2}>
                <Item
                  dataField={"tel"}
                  editorType={"dxTextBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    mask: "+\\9\\0 (000) 000-0000",
                    placeholder: "Tel",
                    onValueChanged: handleChange("tel"),
                  }}
                >
                  <RequiredRule message="Tel is Required." />
                  <Label visible={true} text="Tel" />
                </Item>
                <Item
                  dataField={"distance"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Distance",
                    format: "##0.## KM",
                    onValueChanged: handleChange("distance"),
                  }}
                >
                  <RequiredRule message="Distance is Required." />
                  <Label visible={true} text="Distance" />
                </Item>
              </GroupItem>
              <GroupItem colCount={2}>
                <Item
                  dataField={"coordinateX"}
                  editorType={"dxTextBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "CoordinateX",
                    onValueChanged: handleChange("coordinateX"),
                  }}
                >
                  <RequiredRule message="CoordinateX is Required." />
                  <Label visible={true} text="CoordinateX" />
                </Item>
                <Item
                  dataField={"coordinateY"}
                  editorType={"dxTextBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "CoordinateY",
                    onValueChanged: handleChange("coordinateY"),
                  }}
                >
                  <RequiredRule message="Coordinate Y is Required." />
                  <Label visible={true} text="Coordinate Y" />
                </Item>
              </GroupItem>
              <GroupItem colCount={3}>
                <Item
                  dataField={"bbpWasteDisposalMethod"}
                  editorType={"dxTextBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "BBP Waste Disposal Method",
                    onValueChanged: handleChange("bbpWasteDisposalMethod"),
                  }}
                >
                  <RequiredRule message="BBP Waste Disposal Method is Required." />
                  <Label visible={true} text="BBP Waste Disposal Method" />
                </Item>
                <Item
                  dataField={"bbpStorageTime"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "BBP Storage Time",
                    format: "##0 MM",
                    onValueChanged: handleChange("bbpStorageTime"),
                  }}
                >
                  <RequiredRule message="BBP Storage Time is Required." />
                  <Label visible={true} text="BBP Storage Time" />
                </Item>
                <Item
                  dataField={"bbpManureTransportDistance"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "BBP Manure Transport Distance",
                    format: "##0.## KM",
                    onValueChanged: handleChange("bbpManureTransportDistance"),
                  }}
                >
                  <RequiredRule message="BBP Manure Transport Distance is Required." />
                  <Label visible={true} text="BBP Manure Transport Distance" />
                </Item>
              </GroupItem>
              <ButtonItem>
                <ButtonOptions
                  width={"100%"}
                  type="normal"
                  stylingMode="outlined"
                  useSubmitBehavior={true}
                >
                  <span className="dx-button-text">
                    {loading ? (
                      <LoadIndicator
                        width={"24px"}
                        height={"24px"}
                        visible={true}
                      />
                    ) : (
                      "Kaydet"
                    )}
                  </span>
                </ButtonOptions>
              </ButtonItem>
            </Form>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}
