import React from "react";
import {
  Export,
  Item,
  Toolbar,
  ColumnChooser,
  Column,
  DataGrid,
  FilterRow,
  SearchPanel,
  HeaderFilter,
  Paging,
} from "devextreme-react/data-grid";
import { handleDxExcelExport } from "../../utils/dxExcelExportUtils"; // Dosya yolu doğru ise ayarlayın

export default function CalcDataGridComponent({
  calcList,
  columnMap,
  excelName,
}) {
  function onExporting(e) {
    handleDxExcelExport(e, "sheet1", `${excelName}.xlsx`);
  }

  return (
    <React.Fragment>
      <DataGrid
        dataSource={calcList}
        showBorders={true}
        height={700}
        remoteOperations={true}
        onExporting={onExporting}
        columnAutoWidth={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
      >
        <Toolbar>
          <Item name="exportButton" locateInMenu="auto" />
          <Item location="after" locateInMenu="auto">
            <div className="separator" />
          </Item>
          <Item name="columnChooserButton" locateInMenu="auto" />
          <Item location="after" locateInMenu="auto">
            <div className="separator" />
          </Item>
          <Item name="searchPanel" location="after" />
        </Toolbar>
        <ColumnChooser enabled />
        <SearchPanel
          visible={true}
          highlightCaseSensitive={true}
          placeholder="Search"
          width={250}
        />
        <FilterRow visible={true} />
        <HeaderFilter visible={true} />
        <Paging defaultPageSize={15} />

        {columnMap
          .sort((a, b) => a.order - b.order)
          .map((item) => {
            if (!item.isVisible) return <></>;
            switch (item.dataType) {
              case "string":
                return (
                  <Column dataField={item.dataField} caption={item.caption} />
                );
              case "int":
                return (
                  <Column dataField={item.dataField} caption={item.caption} />
                );
              case "double":
                return (
                  <Column
                    dataField={item.dataField}
                    caption={item.caption}
                    dataType="number"
                    format={"##0.####"}
                  />
                );
              case "Guid":
                return <></>;
              default:
                return <></>;
            }
          })}

        <Export enabled={true} allowExportSelectedData={true} />
      </DataGrid>
    </React.Fragment>
  );
}
