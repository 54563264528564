import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  digesterSelectBoxList: [],
  farmSelectBoxList: [],
  farmLivestockTypeSelectBoxList: [],
  biogasPlantInfoSelectBoxList: [],
  yearlyCarbonEmissionsSelectBoxList: [],
  farmLivestockBreedOriginSelectBoxList: [
    { id: 0, key: "Other", value: "Other" },
    { id: 1, key: "WesternEurope", value: "Western Europe" },
    { id: 2, key: "EasternEurope", value: "Eastern Europe" },
    { id: 3, key: "Oceania", value: "Oceania" },
    { id: 4, key: "LatinAmerica", value: "Latin America" },
    { id: 5, key: "Africa", value: "Africa" },
    { id: 6, key: "MiddleEast", value: "Middle East" },
    { id: 7, key: "Asia", value: "Asia" },
    { id: 8, key: "India", value: "India" },
    { id: 9, key: "DevelopedCountry", value: "Developed Country" },
    { id: 10, key: "DevelopingCountry", value: "Developing Country" },
    { id: 11, key: "NorthAmerica", value: "North America" },
  ],
  livestockTypeSelectBoxList: [
    { id: 0, key: "Other", value: "Other" },
    { id: 1, key: "CattleOther", value: "Cattle Other" },
    { id: 2, key: "CattleDairy", value: "Cattle Dairy" },
    { id: 3, key: "ChickenLayer", value: "Chicken Layer" },
    { id: 4, key: "ChickenBroiler", value: "Chicken Broiler" },
    { id: 5, key: "Sheep", value: "Sheep" },
    { id: 6, key: "Goat", value: "Goat" },
    { id: 7, key: "Horse", value: "Horse" },
    { id: 8, key: "Mule", value: "Mule" },
    { id: 9, key: "Camel", value: "Camel" },
    { id: 10, key: "Pig", value: "Pig" },
    { id: 11, key: "Turkey", value: "Turkey" },
    { id: 12, key: "Goose", value: "Goose" },
    { id: 13, key: "Duck", value: "Duck" },
  ],
};

export const selectBoxLists = createSlice({
  name: "selectBoxLists",
  initialState,
  reducers: {
    setFarmSelectBoxList: (state, action) => {
      state.farmSelectBoxList = action.payload;
    },
    setBiogasPlantInfoSelectBoxList: (state, action) => {
      state.biogasPlantInfoSelectBoxList = action.payload;
    },
    setYearlyCarbonEmissionsSelectBoxList: (state, action) => {
      state.yearlyCarbonEmissionsSelectBoxList = action.payload;
    },
    setFarmLivestockTypeSelectBoxList: (state, action) => {
      state.farmLivestockTypeSelectBoxList = action.payload;
    },
    setDigesterSelectBoxList: (state, action) => {
      state.digesterLivestockTypeSelectBoxList = action.payload;
    },
  },
});

export const {
  setFarmSelectBoxList,
  setBiogasPlantInfoSelectBoxList,
  setYearlyCarbonEmissionsSelectBoxList,
  setFarmLivestockTypeSelectBoxList,
  setDigesterSelectBoxList,
} = selectBoxLists.actions;

export default selectBoxLists.reducer;
