import React from "react";
import {
  Export,
  Item,
  Toolbar,
  ColumnChooser,
  Column,
  Lookup,
  Button,
  DataGrid,
  FilterRow,
  SearchPanel,
  HeaderFilter,
  Paging,
} from "devextreme-react/data-grid";
import { Button as DxButton } from "devextreme-react/button";
import { useNavigate } from "react-router-dom";
import {
  farmDataStore,
  farmLivestockTypeDataStore,
  solidWasteInputDataStore,
} from "../../api/dxDataStores";
import { useSelector } from "react-redux";
import { handleDxExcelExport} from "../../utils/dxExcelExportUtils"; // Dosya yolu doğru ise ayarlayın

export default function SolidWasteInputs() {
  const navigate = useNavigate();
  const livestockTypeSelectBoxList = useSelector(
    (state) => state.selectBoxLists.livestockTypeSelectBoxList
  );
  const farmLivestockBreedOriginSelectBoxList = useSelector(
    (state) => state.selectBoxLists.farmLivestockBreedOriginSelectBoxList
  );
  function onExporting(e) {
    handleDxExcelExport(e, "SolidWasteInput", "SolidWasteInputs.xlsx");
  }
  
  const onEditClick = (e) => {
    navigate(`/inventoryManagement/solidWasteInputs/form/${e.row.data.id}`);
  };
  const onDeleteClick = (e) => {
    console.log("Silme İşlemi");
  };
  const onAddClick = (e) => {
    navigate(
      `/inventoryManagement/solidWasteInputs/form/${"00000000-0000-0000-0000-000000000000"}`
    );
  };
  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <h2 className={"content-block"}>Solid Waste Inputs</h2>
          <DataGrid
            dataSource={solidWasteInputDataStore}
            showBorders={true}
            height={700}
            remoteOperations={true}
            onExporting={onExporting}
            columnAutoWidth={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
          >
            <Toolbar>
              <Item location="after" locateInMenu="auto">
                <DxButton icon="plus" onClick={onAddClick} />
              </Item>
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="exportButton" locateInMenu="auto" />
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="columnChooserButton" locateInMenu="auto" />
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="searchPanel" location="after" />
            </Toolbar>
            <ColumnChooser enabled />
            <SearchPanel
              visible={true}
              highlightCaseSensitive={true}
              placeholder="Search"
              width={250}
            />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Paging defaultPageSize={15} />
            <Column type="buttons">
              <Button hint="Edit" icon="edit" onClick={onEditClick} />
              <Button hint="Delete" icon="trash" onClick={onDeleteClick} />
            </Column>
            <Column dataField="farmId" caption="Farm Name">
              <Lookup
                dataSource={farmDataStore}
                valueExpr="id"
                displayExpr="name"
              />
            </Column>
            <Column dataField="farmLivestockTypeId" caption="Farm Livestock">
              <Lookup
                dataSource={farmLivestockTypeDataStore}
                valueExpr="id"
                displayExpr={(item) => {               
                  return item
                    ? `${
                        livestockTypeSelectBoxList[item.livestockType].value
                      } / ${
                        farmLivestockBreedOriginSelectBoxList[
                          item.farmLivestockBreedOrigin
                        ].value
                      }`
                    : "";
                }}
              />
            </Column>
            <Column
              dataField="entryTime"
              caption="Entry Time"
              dataType="datetime"
              format="dd.MM.yyyy HH:mm"
            />
            <Column
              dataField="tonnage"
              caption="Tonnage"
              dataType="number"
              format={"##0.####"}
            />
            <Column
              dataField="dryMatter"
              caption="Dry Matter"
              dataType="number"
              format={"##0.####"}
            />
            <Column
              dataField="organicDryMatter"
              caption="Organic Dry Matter"
              dataType="number"
              format={"##0.####"}
            />          
            <Export enabled={true} allowExportSelectedData={true} />
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
  );
}
