import axios from "axios";

export async function save(
  id,
  farmId,
  farmLivestockTypeId,
  entryTime,
  krozeDefinition,
  krozeEmptyWeight,
  krozeFullWeight,
  c105,
  c550,
  pH,
  eC
) {
  try {
    var res = await axios.post(
      `${process.env.REACT_APP_API_URL}/liquidWasteAnalysis/save`,
      {
        id,
        farmId,
        farmLivestockTypeId,
        entryTime,
        krozeDefinition,
        krozeEmptyWeight,
        krozeFullWeight,
        c105,
        c550,
        pH,
        eC,
      }
    );

    return {
      isOk: true,
      data: res.data.data,
      message: res.data.message,
    };
  } catch (error) {
    console.log(res);
    return {
      isOk: false,
      message: "Kayıt işlemi başarısız.",
    };
  }
}

export async function getById(id) {
  try {
    var res = await axios.get(
      `${process.env.REACT_APP_API_URL}/liquidWasteAnalysis/getbyid?id=${id}`
    );

    return {
      isOk: true,
      data: res.data,
    };
  } catch (error) {
    return {
      isOk: false,
      message: "İşlem başarısız.",
    };
  }
}

export async function getSelectBoxList() {
  try {
    var res = await axios.get(
      `${process.env.REACT_APP_API_URL}/liquidWasteAnalysis/getSelectBoxList`
    );

    return {
      isOk: true,
      data: res.data,
    };
  } catch (error) {
    return {
      isOk: false,
      message: "İşlem başarısız.",
    };
  }
}
