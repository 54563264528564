import React, { useState, useEffect } from "react";
import { Chart, Series, Legend } from "devextreme-react/chart";
import HomeWidget from "./homeWidget";
import "./home.scss";
import { getDashboardData } from "../../api/dashboard";
export default function Home() {

  const [dashboardData, setDashboardData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const res = await getDashboardData();
      console.log(res.data);
      if (res.isOk) setDashboardData(res.data.data);
    }
    fetchData();
  }, []);


  const dataSource = [
    {
      day: "Monday",
      oranges: 3,
    },
    {
      day: "Tuesday",
      oranges: 2,
    },
    {
      day: "Wednesday",
      oranges: 3,
    },
    {
      day: "Thursday",
      oranges: 4,
    },
    {
      day: "Friday",
      oranges: 6,
    },
    {
      day: "Saturday",
      oranges: 11,
    },
    {
      day: "Sunday",
      oranges: 4,
    },
  ];

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Dashboard</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className="cards compact">
            <HomeWidget
              subject={"Weighbridge Inputs"}
              priority={"low"}
              priorityStr={"Daily"}
              assignee={`${dashboardData.dailyWeighbridgeInputs} Tonnage`}
              onEdit={() => console.log("")}
            />
            <HomeWidget
              subject={"Solid Waste Inputs"}
              priority={"high"}
              priorityStr={"Daily"}
              assignee={`${dashboardData.dailySolidWasteInputs} Tonnage`}
              onEdit={() => console.log("")}
            />
            <HomeWidget
              subject={"Liquid Waste Inputs"}
              priority={"normal"}
              priorityStr={"Daily"}
              assignee={`${dashboardData.dailyLiquidWasteInputs} Tonnage`}
              onEdit={() => console.log("")}
            />
            <HomeWidget
              subject={"Fermented Fertilizer Inputs"}
              priority={"high"}
              priorityStr={"Daily"}
              assignee={`${dashboardData.dailyFermentedFertilizerInputs} Tonnage`}
              onEdit={() => console.log("")}
            />
          </div>
        </div>

        <div className={"dx-card responsive-paddings"}>
          <div className="cards">
            <div className="dx-card  responsive-paddings">
              <Chart id="chart" dataSource={dashboardData.dashboardOsosChartDataList}>
                <Series
                  valueField="production"
                  argumentField="day"
                  name="OSOS Production Datas (Last 1 Month)"
                  type="bar"
                  color="#d9534f"
                />
                <Legend
                  position="outside"
                  verticalAlignment="bottom"
                  horizontalAlignment="center" />
              </Chart>
            </div>
            <div className="dx-card  responsive-paddings">
              <Chart id="chart" dataSource={dashboardData.dashboardDigesterChartDataList}>
                <Series
                  valueField="temperature"
                  argumentField="day"
                  name="Digester Temperature Datas (Last 1 Month)"
                  type="bar"
                  color="#6fbaca"
                />
                <Legend
                  position="outside"
                  verticalAlignment="bottom"
                  horizontalAlignment="center" />
              </Chart>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
