import axios from "axios";

export async function signIn(email, password) {
  try {
    var res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, {
      email: email,
      password: password,
    });

    return {
      isOk: true,
      data: res.data.data,
      message:res.data.message
    };
  } catch (error) {
    console.log(res);
    return {
      isOk: false,
      message:
        "Giriş işlemi başarısız. Lütfen kullanıcı adınızı veya şifrenizi kontrol ediniz.",
    };
  }
}

export async function getUser() {
  try {
    var res = await axios.get(
      `${process.env.REACT_APP_API_URL}/user/getauthuser`
    );
    return {
      isOk: true,
      data: res.data.data,
      message:res.data.message
    };
  } catch (error) {
    console.log(res);
    return {
      isOk: false,
      message:
        "Giriş işlemi başarısız. Lütfen kullanıcı adınızı veya şifrenizi kontrol ediniz.",
    };
  }
}
