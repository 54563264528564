import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import LoadIndicator from "devextreme-react/load-indicator";
import Form, {
  GroupItem,
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from "devextreme-react/form";
import { useSelector } from "react-redux";
import notify from "devextreme/ui/notify";
import { save, getById } from "../../../api/farmLivestockType";

export default function FarmLivestockTypeForm() {
  const { id } = useParams();
  const isEmptyGuid = (guid) => guid === "00000000-0000-0000-0000-000000000000";

  const [formData, setFormData] = useState({
    id: id ?? "",
    livestockType: 0,
    livestockBreed: "",
    farmLivestockBreedOrigin: 0,
    wd: 0,
    b0LT: 0,
    vs: 0,
  });

  const [loading, setLoading] = useState(false);
  const isIdEmpty = isEmptyGuid(id);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getById(id);

      if (result.isOk) {
        setFormData(result.data);
      } else {
        notify(result.message, "error", 2000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (!isIdEmpty) {
      fetchData();
    }
  }, [fetchData, isIdEmpty]);

  const handleChange = (fieldName) => (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: e.value,
    }));
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const {
        livestockType,
        livestockBreed,
        farmLivestockBreedOrigin,
        wd,
        b0LT,
        vs,
      } = formData;

      try {
        setLoading(true);
        const result = await save(
          id,
          livestockType,
          livestockBreed,
          farmLivestockBreedOrigin,
          wd,
          b0LT,
          vs
        );

        if (!result.isOk) {
          notify(result.message, "error", 2000);
        } else {
          notify(result.message, "success", 2000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setLoading(false);
      }
    },
    [id, formData]
  );
  const livestockTypeSelectBoxList = useSelector(
    (state) => state.selectBoxLists.livestockTypeSelectBoxList
  );
  const farmLivestockBreedOriginSelectBoxList = useSelector(
    (state) => state.selectBoxLists.farmLivestockBreedOriginSelectBoxList
  );
  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <h2 className={"content-block"}>
            Biogas Plant Info {isIdEmpty ? "Save" : "Update"} Form
          </h2>
          <form className={"login-form"} onSubmit={onSubmit}>
            <Form formData={formData} disabled={loading}>
              <GroupItem colCount={3}>
                <Item
                  dataField={"livestockType"}
                  editorType={"dxSelectBox"}
                  editorOptions={{
                    items: livestockTypeSelectBoxList,
                    displayExpr: "value",
                    valueExpr: "id",
                    stylingMode: "filled",
                    searchEnabled: true,
                    onValueChanged: handleChange("livestockType"),
                  }}
                >
                  <RequiredRule message="Livestock Type is Required." />
                  <Label visible={true} text="Livestock Type" />
                </Item>
                <Item
                  dataField={"livestockBreed"}
                  editorType={"dxTextBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Livestock Breed",
                    onValueChanged: handleChange("livestockBreed"),
                  }}
                >
                  <RequiredRule message="Livestock Breed is Required." />
                  <Label visible={true} text="Livestock Breed" />
                </Item>
                <Item
                  dataField={"farmLivestockBreedOrigin"}
                  editorType={"dxSelectBox"}
                  editorOptions={{
                    items: farmLivestockBreedOriginSelectBoxList,
                    displayExpr: "value",
                    valueExpr: "id",
                    stylingMode: "filled",
                    searchEnabled: true,
                    onValueChanged: handleChange("farmLivestockBreedOrigin"),
                  }}
                >
                  <RequiredRule message="Farm Livestock Breed Origin is Required." />
                  <Label visible={true} text="Farm Livestock Breed Origin" />
                </Item>
              </GroupItem>
              <GroupItem colCount={3}>
                <Item
                  dataField={"wd"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "WD",
                    format: "##0.####",
                    onValueChanged: handleChange("wd"),
                  }}
                >
                  <RequiredRule message="WD is Required." />
                  <Label visible={true} text="WD" />
                </Item>
                <Item
                  dataField={"b0LT"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "B0LT",
                    format: "##0.####",
                    onValueChanged: handleChange("b0LT"),
                  }}
                >
                  <RequiredRule message="B0LT is Required." />
                  <Label visible={true} text="B0LT" />
                </Item>
                <Item
                  dataField={"vs"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "VS",
                    format: "##0.####",
                    onValueChanged: handleChange("vs"),
                  }}
                >
                  <RequiredRule message="VS is Required." />
                  <Label visible={true} text="VS" />
                </Item>
              </GroupItem>
              <ButtonItem>
                <ButtonOptions
                  width={"100%"}
                  type="normal"
                  stylingMode="outlined"
                  useSubmitBehavior={true}
                >
                  <span className="dx-button-text">
                    {loading ? (
                      <LoadIndicator
                        width={"24px"}
                        height={"24px"}
                        visible={true}
                      />
                    ) : (
                      "Kaydet"
                    )}
                  </span>
                </ButtonOptions>
              </ButtonItem>
            </Form>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}
