import React, { useState, useRef, useCallback } from "react";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import notify from "devextreme/ui/notify";
import { signIn, getUser } from "../../api/auth";
import { useDispatch } from "react-redux";
import { setToken } from "../../configure/slices/auth-slice";
import { setUser } from "../../configure/slices/user-slice";
import {
  setBiogasPlantInfoSelectBoxList,
  setFarmSelectBoxList,
  setYearlyCarbonEmissionsSelectBoxList,
  setFarmLivestockTypeSelectBoxList,
} from "../../configure/slices/selectBoxLists-slice";
import { getSelectBoxList as getFarmSelectBoxList } from "../../api/farm";
import { getSelectBoxList as getBiogasPlantInfoSelectBoxList } from "../../api/biogasPlantInfo";
import { getSelectBoxList as getYearlyCarbonEmissionsSelectBoxList } from "../../api/yearlyCarbonEmissions";
import { getSelectBoxList as getFarmLivestockTypeSelectBoxList } from "../../api/farmLivestockType";
import "./LoginForm.scss";
import yigilabLogo from "../../assets/yigilablogo.png";

export default function LoginForm() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const formData = useRef({
    email: "superadmin@yigilab.com",
    password: "Yg.,2020",
  });

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { email, password } = formData.current;
      setLoading(true);
      const result = await signIn(email, password);
      if (!result.isOk) {
        setLoading(false);
        notify(result.message, "error", 2000);
      } else {
        dispatch(setToken(result.data));
        const user = await getUser();
        dispatch(setUser(user.data));
        const farmSelectBoxList = await getFarmSelectBoxList();
        if (farmSelectBoxList.isOk)
          dispatch(setFarmSelectBoxList(farmSelectBoxList.data));
        const biogasPlantInfoSelectBoxList =
          await getBiogasPlantInfoSelectBoxList();
        if (biogasPlantInfoSelectBoxList.isOk)
          dispatch(
            setBiogasPlantInfoSelectBoxList(biogasPlantInfoSelectBoxList.data)
          );
        const yearlyCarbonEmissionsSelectBoxList =
          await getYearlyCarbonEmissionsSelectBoxList();
        if (yearlyCarbonEmissionsSelectBoxList.isOk)
          dispatch(
            setYearlyCarbonEmissionsSelectBoxList(
              yearlyCarbonEmissionsSelectBoxList.data
            )
          );
        const farmLivestockTypeSelectBoxList =
          await getFarmLivestockTypeSelectBoxList();
        if (farmLivestockTypeSelectBoxList.isOk)
          dispatch(
            setFarmLivestockTypeSelectBoxList(
              farmLivestockTypeSelectBoxList.data
            )
          );
      }
    },
    [dispatch]
  );

  return (
    <>
      <img
        style={{ width: "100%", height: "auto" }}
        alt={""}
        src={yigilabLogo}
      />
      <form className={"login-form"} onSubmit={onSubmit}>
        <Form formData={formData.current} disabled={loading}>
          <Item
            dataField={"email"}
            editorType={"dxTextBox"}
            editorOptions={emailEditorOptions}
          >
            <RequiredRule message="Lütfen E-Postanızı Giriniz." />
            <EmailRule message="Lütfen E-Posta bilgisini uygun bir şekilde giriniz." />
            <Label visible={false} />
          </Item>
          <Item
            dataField={"password"}
            editorType={"dxTextBox"}
            editorOptions={passwordEditorOptions}
          >
            <RequiredRule message="Lütfen Şifrenizi Giriniz." />
            <Label visible={false} />
          </Item>

          <ButtonItem>
            <ButtonOptions
              width={"100%"}
              type="default"
              stylingMode="outlined"
              useSubmitBehavior={true}
            >
              <span className="dx-button-text">
                {loading ? (
                  <LoadIndicator
                    width={"24px"}
                    height={"24px"}
                    visible={true}
                  />
                ) : (
                  "Giriş Yap"
                )}
              </span>
            </ButtonOptions>
          </ButtonItem>
        </Form>
      </form>
    </>
  );
}

const emailEditorOptions = {
  stylingMode: "filled",
  placeholder: "E-Posta",
  mode: "email",
};
const passwordEditorOptions = {
  stylingMode: "filled",
  placeholder: "Şifre",
  mode: "password",
};
