import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import LoadIndicator from "devextreme-react/load-indicator";
import Form, {
  GroupItem,
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from "devextreme-react/form";
import { useDispatch } from "react-redux";
import notify from "devextreme/ui/notify";
import { save, getById } from "../../../api/fosTacAnalysis";
import { getSelectBoxList } from "../../../api/digester";
export default function FosTacAnalysisForm() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isEmptyGuid = (guid) => guid === "00000000-0000-0000-0000-000000000000";

  const [formData, setFormData] = useState({
    id: id ?? "",
    digesterId: "",
    entryTime: new Date(),
    ph: 0,
    ec: 0,
    t: 0,
    fos: 0,
    tac: 0,
  });

  const [loading, setLoading] = useState(false);
  const isIdEmpty = isEmptyGuid(id);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getById(id);

      if (result.isOk) {
        setFormData(result.data);
      } else {
        notify(result.message, "error", 2000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (!isIdEmpty) {
      fetchData();
    }
  }, [fetchData, isIdEmpty]);

  const handleChange = (fieldName) => (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: e.value,
    }));
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { digesterId, entryTime, ph, ec, t, fos, tac } = formData;

      try {
        setLoading(true);
        const result = await save(
          id,
          digesterId,
          entryTime,
          ph,
          ec,
          t,
          fos,
          tac
        );

        if (!result.isOk) {
          notify(result.message, "error", 2000);
        } else {
          notify(result.message, "success", 2000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setLoading(false);
      }
    },
    [id, formData, dispatch]
  );
  const [digesterItems, setDigesterItems] = useState([]);
  useEffect(() => {
    const fetchSelectBoxData = async () => {
      try {
        const result = await getSelectBoxList();

        if (result.isOk) {
          setDigesterItems(result.data); // Assuming result.data is an array of items
        } else {
          notify(result.message, "error", 2000);
        }
      } catch (error) {
        console.error("Error fetching select box data:", error);
      }
    };

    fetchSelectBoxData();
  }, []);
  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <h2 className={"content-block"}>
            FosTac Analysis {isIdEmpty ? "Save" : "Update"} Form
          </h2>
          <form className={"login-form"} onSubmit={onSubmit}>
            <Form formData={formData} disabled={loading}>
              <GroupItem colCount={2}>
                <Item
                  dataField={"digesterId"}
                  editorType={"dxSelectBox"}
                  editorOptions={{
                    items: digesterItems,
                    displayExpr: "text",
                    valueExpr: "value",
                    stylingMode: "filled",
                    searchEnabled: true,
                    onValueChanged: handleChange("digesterId"),
                  }}
                >
                  <RequiredRule message="Digester is Required." />
                  <Label visible={true} text="Digester" />
                </Item>
                <Item
                  dataField={"entryTime"}
                  editorType={"dxDateBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Entry Time",
                    type: "datetime",
                    displayFormat: "dd.MM.yyyy HH:mm",
                    onValueChanged: handleChange("entryTime"),
                  }}
                >
                  <RequiredRule message="Entry Time is Required." />
                  <Label visible={true} text="Entry Time" />
                </Item>
              </GroupItem>
              <GroupItem colCount={3}>
                <Item
                  dataField={"ph"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "PH",
                    format: "##0.####",
                    onValueChanged: handleChange("ph"),
                  }}
                >
                  <RequiredRule message="PH is Required." />
                  <Label visible={true} text="PH" />
                </Item>
                <Item
                  dataField={"ec"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "EC",
                    format: "##0.####",
                    onValueChanged: handleChange("ec"),
                  }}
                >
                  <RequiredRule message="EC is Required." />
                  <Label visible={true} text="EC" />
                </Item>
                <Item
                  dataField={"t"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "T",
                    format: "##0.####",
                    onValueChanged: handleChange("t"),
                  }}
                >
                  <RequiredRule message="T is Required." />
                  <Label visible={true} text="T" />
                </Item>
              </GroupItem>
              <GroupItem colCount={2}>
                <Item
                  dataField={"fos"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Fos",
                    format: "##0.####",
                    onValueChanged: handleChange("fos"),
                  }}
                >
                  <RequiredRule message="Fos is Required." />
                  <Label visible={true} text="Fos" />
                </Item>
                <Item
                  dataField={"tac"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Tac",
                    format: "##0.####",
                    onValueChanged: handleChange("tac"),
                  }}
                >
                  <RequiredRule message="Tac is Required." />
                  <Label visible={true} text="Tac Distance" />
                </Item>
              </GroupItem>
              <ButtonItem>
                <ButtonOptions
                  width={"100%"}
                  type="normal"
                  stylingMode="outlined"
                  useSubmitBehavior={true}
                >
                  <span className="dx-button-text">
                    {loading ? (
                      <LoadIndicator
                        width={"24px"}
                        height={"24px"}
                        visible={true}
                      />
                    ) : (
                      "Kaydet"
                    )}
                  </span>
                </ButtonOptions>
              </ButtonItem>
            </Form>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}
