import axios from "axios";

export async function save(
  id,
  farmId,
  farmLivestockTypeId,
  entryDate,
  tonnage,
  km,
  okm
) {
  try {    
    var res = await axios.post(
      `${process.env.REACT_APP_API_URL}/weighbridgeData/save`,
      {
        id,
        farmId,
        farmLivestockTypeId,
        entryDate,
        tonnage,
        km,
        okm,
      }
    );

    return {
      isOk: true,
      data: res.data.data,
      message:res.data.message
    };
  } catch (error) {    
      console.log(res);
    return {
      isOk: false,
      message: "Kayıt işlemi başarısız.",
    };
  }
}

export async function getById(id) {
  try {
    var res = await axios.get(
      `${process.env.REACT_APP_API_URL}/weighbridgeData/getbyid?id=${id}`
    );

    return {
      isOk: true,
      data: res.data,
    };
  } catch (error) {
    return {
      isOk: false,
      message: "İşlem başarısız.",
    };
  }
}

export async function getWeighbridgeChartData() {
  try {
    var res = await axios.get(
      `${process.env.REACT_APP_API_URL}/weighbridgeData/getWeighbridgeChartData`
    );

    return {
      isOk: true,
      data: res.data,
    };
  } catch (error) {
    return {
      isOk: false,
      message: "İşlem başarısız.",
    };
  }
}
