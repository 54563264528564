import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import LoadIndicator from "devextreme-react/load-indicator";
import Form, {
  GroupItem,
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import { useDispatch } from "react-redux";
import {
  save,
  getById,
  getSelectBoxList,
} from "../../../api/biogasPlantInfo";
import { setBiogasPlantInfoSelectBoxList } from "../../../configure/slices/selectBoxLists-slice";

export default function BiogasPlantInfoForm() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isEmptyGuid = (guid) => guid === "00000000-0000-0000-0000-000000000000";

  const [formData, setFormData] = useState({
    id: id ?? "",
    name: "",
    coordinateX: "",
    coordinateY: "",
    biogasReactorType: "",
    biogasReactorBuilgingMaterial: "",
  });

  const [loading, setLoading] = useState(false);
  const isIdEmpty = isEmptyGuid(id);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getById(id);

      if (result.isOk) {
        setFormData(result.data);
      } else {
        notify(result.message, "error", 2000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (!isIdEmpty) {
      fetchData();
    }
  }, [fetchData, isIdEmpty]);

  const handleChange = (fieldName) => (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: e.value,
    }));
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const {
        name,
        coordinateX,
        coordinateY,
        biogasReactorType,
        biogasReactorBuilgingMaterial,
      } = formData;

      try {
        setLoading(true);
        const result = await save(
          id,
          name,
          coordinateX,
          coordinateY,
          biogasReactorType,
          biogasReactorBuilgingMaterial
        );

        if (!result.isOk) {
          notify(result.message, "error", 2000);
        } else {
          notify(result.message, "success", 2000);
          const biogasPlantInfoSelectBoxList = await getSelectBoxList();
          if (biogasPlantInfoSelectBoxList.isOk)
            dispatch(
              setBiogasPlantInfoSelectBoxList(biogasPlantInfoSelectBoxList.data)
            );
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setLoading(false);
      }
    },
    [id, formData,dispatch]
  );

  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <h2 className={"content-block"}>
            Biogas Plant Info {isIdEmpty ? "Save" : "Update"} Form
          </h2>
          <form className={"login-form"} onSubmit={onSubmit}>
            <Form formData={formData} disabled={loading}>
              <GroupItem colCount={2}>
                <Item
                  dataField={"name"}
                  editorType={"dxTextBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Name",
                    onValueChanged: handleChange("name"),
                  }}
                >
                  <RequiredRule message="Name is Required." />
                  <Label visible={true} text="Name" />
                </Item>
              </GroupItem>
              <GroupItem colCount={2}>
                <Item
                  dataField={"coordinateX"}
                  editorType={"dxTextBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "CoordinateX",
                    onValueChanged: handleChange("coordinateX"),
                  }}
                >
                  <RequiredRule message="CoordinateX is Required." />
                  <Label visible={true} text="CoordinateX" />
                </Item>
                <Item
                  dataField={"coordinateY"}
                  editorType={"dxTextBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "CoordinateY",
                    onValueChanged: handleChange("coordinateY"),
                  }}
                >
                  <RequiredRule message="CoordinateY is Required." />
                  <Label visible={true} text="CoordinateY" />
                </Item>
              </GroupItem>
              <GroupItem colCount={2}>
                <Item
                  dataField={"biogasReactorType"}
                  editorType={"dxTextBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Biogas Reactor Type",
                    onValueChanged: handleChange("biogasReactorType"),
                  }}
                >
                  <RequiredRule message="Biogas Reactor Type is Required." />
                  <Label visible={true} text="Biogas Reactor Type" />
                </Item>
                <Item
                  dataField={"biogasReactorBuilgingMaterial"}
                  editorType={"dxTextBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Biogas Reactor Builging Material",
                    onValueChanged: handleChange(
                      "biogasReactorBuilgingMaterial"
                    ),
                  }}
                >
                  <RequiredRule message="Biogas Reactor Builging Material is Required." />
                  <Label
                    visible={true}
                    text="Biogas Reactor Builging Material"
                  />
                </Item>
              </GroupItem>
              <ButtonItem>
                <ButtonOptions
                  width={"100%"}
                  type="normal"
                  stylingMode="outlined"
                  useSubmitBehavior={true}
                >
                  <span className="dx-button-text">
                    {loading ? (
                      <LoadIndicator
                        width={"24px"}
                        height={"24px"}
                        visible={true}
                      />
                    ) : (
                      "Kaydet"
                    )}
                  </span>
                </ButtonOptions>
              </ButtonItem>
            </Form>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}
