import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import LoadIndicator from "devextreme-react/load-indicator";
import Form, {
  GroupItem,
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from "devextreme-react/form";
import { useDispatch } from "react-redux";
import notify from "devextreme/ui/notify";
import { save, getById } from "../../../api/ososData";

export default function OsosDataForm() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isEmptyGuid = (guid) => guid === "00000000-0000-0000-0000-000000000000";

  const [formData, setFormData] = useState({
    id: id ?? "",
    entryTime: new Date(),
    consumptionDraw: 0,
    reactiveInductiveDraw: 0,
    reactiveCapacitiveDraw: 0,
    production: 0,
    reactiveInductivePower: 0,
    reactiveCapacitivePower: 0,
  });

  const [loading, setLoading] = useState(false);
  const isIdEmpty = isEmptyGuid(id);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getById(id);

      if (result.isOk) {
        setFormData(result.data);
      } else {
        notify(result.message, "error", 2000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (!isIdEmpty) {
      fetchData();
    }
  }, [fetchData, isIdEmpty]);

  const handleChange = (fieldName) => (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: e.value,
    }));
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const {
        entryTime,
        consumptionDraw,
        reactiveInductiveDraw,
        reactiveCapacitiveDraw,
        production,
        reactiveInductivePower,
        reactiveCapacitivePower,
      } = formData;

      try {
        setLoading(true);
        const result = await save(
          id,
          entryTime,
          consumptionDraw,
          reactiveInductiveDraw,
          reactiveCapacitiveDraw,
          production,
          reactiveInductivePower,
          reactiveCapacitivePower
        );

        if (!result.isOk) {
          notify(result.message, "error", 2000);
        } else {
          notify(result.message, "success", 2000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setLoading(false);
      }
    },
    [id, formData, dispatch]
  );

  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <h2 className={"content-block"}>
            Osos Data {isIdEmpty ? "Save" : "Update"} Form
          </h2>
          <form className={"login-form"} onSubmit={onSubmit}>
            <Form formData={formData} disabled={loading}>
              <GroupItem colCount={2}>
                <Item
                  dataField={"entryTime"}
                  editorType={"dxDateBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Entry Time",
                    type: "datetime",
                    displayFormat: "dd.MM.yyyy HH:mm",
                    onValueChanged: handleChange("entryTime"),
                  }}
                >
                  <RequiredRule message="Entry Time is Required." />
                  <Label visible={true} text="Entry Time" />
                </Item>
              </GroupItem>
              <GroupItem colCount={2}>
                <Item
                  dataField={"consumptionDraw"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Consumption Draw",
                    format: "##0.####",
                    onValueChanged: handleChange("consumptionDraw"),
                  }}
                >
                  <RequiredRule message="Consumption Draw is Required." />
                  <Label visible={true} text="Consumption Draw" />
                </Item>
                <Item
                  dataField={"production"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Production",
                    format: "##0.####",
                    onValueChanged: handleChange("production"),
                  }}
                >
                  <RequiredRule message="Production is Required." />
                  <Label visible={true} text="Production" />
                </Item>
              </GroupItem>
              <GroupItem colCount={2}>
                <Item
                  dataField={"reactiveInductiveDraw"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Reactive Inductive Draw",
                    format: "##0.####",
                    onValueChanged: handleChange("reactiveInductiveDraw"),
                  }}
                >
                  <RequiredRule message="Reactive Inductive Draw is Required." />
                  <Label visible={true} text="Reactive Inductive Draw Time" />
                </Item>
                <Item
                  dataField={"reactiveInductivePower"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Reactive Inductive Power",
                    format: "##0.####",
                    onValueChanged: handleChange("reactiveInductivePower"),
                  }}
                >
                  <RequiredRule message="Reactive Inductive Power is Required." />
                  <Label
                    visible={true}
                    text="Reactive Inductive Power Distance"
                  />
                </Item>
              </GroupItem>
              <GroupItem colCount={2}>
                <Item
                  dataField={"reactiveCapacitiveDraw"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Reactive Capacitive Draw",
                    format: "##0.####",
                    onValueChanged: handleChange("reactiveCapacitiveDraw"),
                  }}
                >
                  <RequiredRule message="Reactive Capacitive Draw is Required." />
                  <Label visible={true} text="Reactive Capacitive Draw Time" />
                </Item>
                <Item
                  dataField={"reactiveCapacitivePower"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Reactive Capacitive Power",
                    format: "##0.####",
                    onValueChanged: handleChange("reactiveCapacitivePower"),
                  }}
                >
                  <RequiredRule message="Reactive Capacitive Power is Required." />
                  <Label
                    visible={true}
                    text="Reactive Capacitive Power Distance"
                  />
                </Item>
              </GroupItem>
              <ButtonItem>
                <ButtonOptions
                  width={"100%"}
                  type="normal"
                  stylingMode="outlined"
                  useSubmitBehavior={true}
                >
                  <span className="dx-button-text">
                    {loading ? (
                      <LoadIndicator
                        width={"24px"}
                        height={"24px"}
                        visible={true}
                      />
                    ) : (
                      "Kaydet"
                    )}
                  </span>
                </ButtonOptions>
              </ButtonItem>
            </Form>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}
