import axios from "axios";

export async function getDashboardData(id) {
  try {
    var res = await axios.get(
      `${process.env.REACT_APP_API_URL}/dashboard/getDashboardData`
    );

    return {
      isOk: true,
      data: res.data,
    };
  } catch (error) {
    return {
      isOk: false,
      message: "İşlem başarısız.",
    };
  }
}
