import React, { useState } from "react";
import { List, Button, TagBox, TextBox } from "devextreme-react";

export default function UserSaveWizard() {
  const [user, setUser] = useState({ name: "", surname: "" });
  const [step, setStep] = useState(1);

  const handleNameChange = (e) => setUser({ ...user, name: e.value });
  const handleSurnameChange = (e) => setUser({ ...user, surname: e.value });

  const nextStep = () => setStep(step + 1);

  // Adımlar için içerik render edilir
  const renderStepContent = (step) => {
    switch (step) {
      case 1:
        return (
          <div>
            <TextBox
              value={user.name}
              onValueChanged={handleNameChange}
              placeholder="Ad"
            />
            <TextBox
              value={user.surname}
              onValueChanged={handleSurnameChange}
              placeholder="Soyad"
            />
            <Button text="İleri" onClick={nextStep} />
          </div>
        );
      case 2:
        return (
          <div>
            <TagBox
              items={[
                { id: 1, name: "manager" },
                { id: 2, name: "admin" },
              ]} // Rol listesi API'dan çekilebilir
              displayExpr="name"
              valueExpr="id"
              onValueChanged={(e) => setUser({ ...user, roles: e.value })}
            />
            <Button text="İleri" onClick={nextStep} />
          </div>
        );
      case 3:
        return (
          <div>
            <List
              items={[
                { label: "Ad", value: user.name },
                { label: "Soyad", value: user.surname },
                {
                  label: "Roller",
                  value: user.roles.map((role) => role.name).join(", "),
                },
              ]}
              itemRender={({ label, value }) => (
                <div>
                  {label}: {value}
                </div>
              )}
            />
            <Button
              text="Kaydet"
              onClick={() => console.log("Kaydedildi:", user)}
            />
          </div>
        );
    }
  };

  return <div>{renderStepContent(step)}</div>;
}
