import React from "react";
import {
  Export,
  Item,
  Toolbar,
  ColumnChooser,
  Column,
  Button,
  DataGrid,
  FilterRow,
  SearchPanel,
  HeaderFilter,
  Paging,
} from "devextreme-react/data-grid";
import { Button as DxButton } from "devextreme-react/button";
import { useNavigate } from "react-router-dom";
import { farmDataStore } from "../../api/dxDataStores";
import { handleDxExcelExport } from "../../utils/dxExcelExportUtils"; // Dosya yolu doğru ise ayarlayın

export default function Farms() {
  const navigate = useNavigate();
  function onExporting(e) {
    handleDxExcelExport(e, "Farm", "Farms.xlsx");
  }

  const onEditClick = (e) => {
    navigate(`/rawMaterialManagement/farms/form/${e.row.data.id}`);
  };
  const onDeleteClick = (e) => {
    console.log("Silme İşlemi");
  };
  const onAddClick = (e) => {
    navigate(`/rawMaterialManagement/farms/form/${"00000000-0000-0000-0000-000000000000"}`);
  };
  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <h2 className={"content-block"}>Farms</h2>
          <DataGrid
            dataSource={farmDataStore}
            showBorders={true}
            height={700}
            remoteOperations={true}
            onExporting={onExporting}
            columnAutoWidth={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
          >
            <Toolbar>
              <Item location="after" locateInMenu="auto">
                <DxButton icon="plus" onClick={onAddClick} />
              </Item>
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="exportButton" locateInMenu="auto" />
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="columnChooserButton" locateInMenu="auto" />
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="searchPanel" location="after" />
            </Toolbar>
            <ColumnChooser enabled />
            <SearchPanel
              visible={true}
              highlightCaseSensitive={true}
              placeholder="Search"
              width={250}
            />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Paging defaultPageSize={15} />
            <Column type="buttons">
              <Button hint="Edit" icon="edit" onClick={onEditClick} />
              <Button hint="Delete" icon="trash" onClick={onDeleteClick} />
            </Column>
            <Column dataField="name" caption="Name" />
            <Column dataField="address" caption="Address" />
            <Column
              dataField="tel"
              caption="Tel"
              dataType="text"
              calculateCellValue={(data) => {
                return `+90 (${data.tel.substr(0, 3)}) ${data.tel.substr(3,3)} ${data.tel.substr(6, 4)}`;
              }}
            />
            <Column
              dataField="distance"
              caption="Distance"
              dataType="number"
              format={"##0.####"}
            />
            <Column
              dataField="coordinateX"
              caption="Coordinate X"
              dataType="text"
            />
            <Column
              dataField="coordinateY"
              caption="Coordinate Y"
              dataType="text"
            />
            <Column
              dataField="bbpWasteDisposalMethod"
              caption="BBP Waste Disposal Method"
            />
            <Column
              dataField="bbpStorageTime"
              caption="BBP Storage Time"
              dataType="number"
              format={"##0.####"}
            />
            <Column
              dataField="bbpManureTransportDistance"
              caption="BBP Manure Transport Distance"
              dataType="number"
              format={"##0.####"}
            />

            <Export enabled={true} allowExportSelectedData={true} />
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
  );
}
