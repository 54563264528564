import React from "react";
import {
  Export,
  Item,
  Toolbar,
  ColumnChooser,
  Column,
  Button,
  DataGrid,
  FilterRow,
  SearchPanel,
  HeaderFilter,
  Paging,
} from "devextreme-react/data-grid";
import { Button as DxButton } from "devextreme-react/button";
import { useNavigate } from "react-router-dom";
import { digesterDataStore } from "../../api/dxDataStores";
import { handleDxExcelExport} from "../../utils/dxExcelExportUtils"; // Dosya yolu doğru ise ayarlayın

export default function Farms() {
  const navigate = useNavigate();
  function onExporting(e) {
    handleDxExcelExport(e, "Digester", "Digesters.xlsx");
  }

  const onEditClick = (e) => {
    navigate(`/processManagement/digesters/form/${e.row.data.id}`);
  };
  const onDeleteClick = (e) => {
    console.log("Silme İşlemi");
  };
  const onAddClick = (e) => {
    navigate(`/processManagement/digesters/form/${"00000000-0000-0000-0000-000000000000"}`);
  };
  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <h2 className={"content-block"}>Digesters</h2>
          <DataGrid
            dataSource={digesterDataStore}
            showBorders={true}
            height={700}
            remoteOperations={true}
            onExporting={onExporting}
            columnAutoWidth={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
          >
            <Toolbar>
              <Item location="after" locateInMenu="auto">
                <DxButton icon="plus" onClick={onAddClick} />
              </Item>
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="exportButton" locateInMenu="auto" />
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="columnChooserButton" locateInMenu="auto" />
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="searchPanel" location="after" />
            </Toolbar>
            <ColumnChooser enabled />
            <SearchPanel
              visible={true}
              highlightCaseSensitive={true}
              placeholder="Search"
              width={250}
            />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Paging defaultPageSize={15} />
            <Column type="buttons">
              <Button hint="Edit" icon="edit" onClick={onEditClick} />
              <Button hint="Delete" icon="trash" onClick={onDeleteClick} />
            </Column>
            <Column dataField="name" caption="Name" />
            <Column
              dataField="diameter"
              caption="Diameter"
              dataType="number"
              format={"##0.####"}
            />
            <Column
              dataField="height"
              caption="Height"
              dataType="number"
              format={"##0.####"}
            />
            <Column
              dataField="maxFillRate"
              caption="MaxFillRate"
              dataType="number"
              format={"##0.####"}
            />
            <Column
              dataField="maxPressure"
              caption="MaxPressure"
              dataType="number"
              format={"##0.####"}
            />
            <Column
              dataField="minPressure"
              caption="MinPressure"
              dataType="number"
              format={"##0.####"}
            />
            <Export enabled={true} allowExportSelectedData={true} />
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
  );
}
