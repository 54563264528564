import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import LoadIndicator from "devextreme-react/load-indicator";
import Form, {
  GroupItem,
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from "devextreme-react/form";
import { useDispatch } from "react-redux";
import notify from "devextreme/ui/notify";
import { save, getById } from "../../../api/liquidWasteAnalysis";
import { useSelector } from "react-redux";

export default function LiquidWasteAnalysisForm() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isEmptyGuid = (guid) => guid === "00000000-0000-0000-0000-000000000000";

  const [formData, setFormData] = useState({
    id: id ?? "",
    farmId: "",
    farmLivestockTypeId: "",
    entryTime: new Date(),
    krozeDefinition: "",
    krozeEmptyWeight: 0,
    krozeFullWeight: 0,
    c105: 0,
    c550: 0,
    ph: 0,
    ec: 0,
  });

  const [loading, setLoading] = useState(false);
  const isIdEmpty = isEmptyGuid(id);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getById(id);

      if (result.isOk) {
        setFormData(result.data);
      } else {
        notify(result.message, "error", 2000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (!isIdEmpty) {
      fetchData();
    }
  }, [fetchData, isIdEmpty]);

  const handleChange = (fieldName) => (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: e.value,
    }));
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const {
        farmId,
        farmLivestockTypeId,
        entryTime,
        krozeDefinition,
        krozeEmptyWeight,
        krozeFullWeight,
        c105,
        c550,
        ph,
        ec,
      } = formData;

      try {
        setLoading(true);
        const result = await save(
          id,
          farmId,
          farmLivestockTypeId,
          entryTime,
          krozeDefinition,
          krozeEmptyWeight,
          krozeFullWeight,
          c105,
          c550,
          ph,
          ec
        );

        if (!result.isOk) {
          notify(result.message, "error", 2000);
        } else {
          notify(result.message, "success", 2000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setLoading(false);
      }
    },
    [id, formData, dispatch]
  );

  const farmSelectBoxList = useSelector(
    (state) => state.selectBoxLists.farmSelectBoxList
  );
  const farmLivestockTypeSelectBoxList = useSelector(
    (state) => state.selectBoxLists.farmLivestockTypeSelectBoxList
  );
  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <h2 className={"content-block"}>
            Liquid Waste Analysis {isIdEmpty ? "Save" : "Update"} Form
          </h2>
          <form className={"login-form"} onSubmit={onSubmit}>
            <Form formData={formData} disabled={loading}>
              <GroupItem colCount={2}>
                <Item
                  dataField={"farmId"}
                  editorType={"dxSelectBox"}
                  editorOptions={{
                    items: farmSelectBoxList,
                    displayExpr: "text",
                    valueExpr: "value",
                    stylingMode: "filled",
                    searchEnabled: true,
                    onValueChanged: handleChange("farmId"),
                  }}
                >
                  <RequiredRule message="Farm is Required." />
                  <Label visible={true} text="Farm" />
                </Item>
                <Item
                  dataField={"farmLivestockTypeId"}
                  editorType={"dxSelectBox"}
                  editorOptions={{
                    items: farmLivestockTypeSelectBoxList,
                    displayExpr: "text",
                    valueExpr: "value",
                    stylingMode: "filled",
                    searchEnabled: true,
                    onValueChanged: handleChange("farmLivestockTypeId"),
                  }}
                >
                  <RequiredRule message="Farm Livestock Type is Required." />
                  <Label visible={true} text="Farm Livestock Type" />
                </Item>
              </GroupItem>
              <GroupItem colCount={2}>
                <Item
                  dataField={"entryTime"}
                  editorType={"dxDateBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Entry Time",
                    type: "datetime",
                    displayFormat: "dd.MM.yyyy HH:mm",
                    onValueChanged: handleChange("entryTime"),
                  }}
                >
                  <RequiredRule message="Entry Time is Required." />
                  <Label visible={true} text="Entry Time" />
                </Item>
              </GroupItem>
              <GroupItem colCount={3}>
                <Item
                  dataField={"krozeDefinition"}
                  editorType={"dxTextBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Kroze Definition",
                    onValueChanged: handleChange("krozeDefinition"),
                  }}
                >
                  <RequiredRule message="Kroze Definition is Required." />
                  <Label visible={true} text="Kroze Definition" />
                </Item>
                <Item
                  dataField={"krozeEmptyWeight"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Kroze Empty Weight",
                    onValueChanged: handleChange("krozeEmptyWeight"),
                  }}
                >
                  <RequiredRule message="Kroze Empty Weight is Required." />
                  <Label visible={true} text="Kroze Empty Weight" />
                </Item>

                <Item
                  dataField={"krozeFullWeight"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Kroze Full Weight",
                    onValueChanged: handleChange("krozeFullWeight"),
                  }}
                >
                  <RequiredRule message="Kroze Full Weight is Required." />
                  <Label visible={true} text="Kroze Full Weight" />
                </Item>
              </GroupItem>
              <GroupItem colCount={2}>
                <Item
                  dataField={"c105"}
                  editorType={"dxTextBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "C105",
                    onValueChanged: handleChange("c105"),
                  }}
                >
                  <RequiredRule message="C105 is Required." />
                  <Label visible={true} text="C105" />
                </Item>
                <Item
                  dataField={"c550"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "C550",
                    onValueChanged: handleChange("c550"),
                  }}
                >
                  <RequiredRule message="C550 is Required." />
                  <Label visible={true} text="C550" />
                </Item>
              </GroupItem>
              <GroupItem colCount={2}>
                <Item
                  dataField={"ph"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "PH",
                    onValueChanged: handleChange("ph"),
                  }}
                >
                  <RequiredRule message="PH is Required." />
                  <Label visible={true} text="PH" />
                </Item>
                <Item
                  dataField={"ec"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "EC",
                    onValueChanged: handleChange("ec"),
                  }}
                >
                  <RequiredRule message="EC is Required." />
                  <Label visible={true} text="EC" />
                </Item>
              </GroupItem>
              <ButtonItem>
                <ButtonOptions
                  width={"100%"}
                  type="normal"
                  stylingMode="outlined"
                  useSubmitBehavior={true}
                >
                  <span className="dx-button-text">
                    {loading ? (
                      <LoadIndicator
                        width={"24px"}
                        height={"24px"}
                        visible={true}
                      />
                    ) : (
                      "Kaydet"
                    )}
                  </span>
                </ButtonOptions>
              </ButtonItem>
            </Form>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}
