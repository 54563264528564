import { createSlice } from "@reduxjs/toolkit";


const defaultTokenModel={token:"",expiration:"1989-12-03T22:28:06.3101443+03:00"};
const localStorageToken=localStorage.getItem('btys2_token');
var tokenDto=JSON.parse(localStorageToken)??defaultTokenModel;
const expirationDate =new Date(tokenDto.expiration);

const now=new Date();
if(expirationDate<now){
  tokenDto=defaultTokenModel
  localStorage.removeItem('btys2_token')
}

const initialState = {
  token:tokenDto
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem('btys2_token', JSON.stringify(action.payload)); 
    },
  },
});

export const { setToken } = authSlice.actions;

export default authSlice.reducer;
