import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import LoadIndicator from "devextreme-react/load-indicator";
import Form, {
  GroupItem,
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import { save, getById } from "../../../api/farmSurvey";
import { useSelector } from "react-redux";

export default function FarmSurveyForm() {
  const { id } = useParams();
  const isEmptyGuid = (guid) => guid === "00000000-0000-0000-0000-000000000000";

  const [formData, setFormData] = useState({
    id: id ?? "",
    yearlyCarbonEmissionsId: "",
    farmId: "",
    farmLivestockTypeId: "",
    animalCount: 0,
    livestockLiveResidenceTime: 0,
    livestockManureStorageTime: 0,
    manureStorageAreaDimensions: "",
    averageLivestockLiveWeight: 0,
    operatingCapacity: 0,
    fertilizerIntakeFrequency: 0,
    farmOfficerName: "",
    farmOfficerTel: "",
    farmManureReceivedRate: 0,
    directNitrogenMonoxideEmissions: 0,
    inDirectNitrogenMonoxideEmissions: 0,
  });

  const [loading, setLoading] = useState(false);
  const isIdEmpty = isEmptyGuid(id);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getById(id);

      if (result.isOk) {
        setFormData(result.data);
      } else {
        notify(result.message, "error", 2000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (!isIdEmpty) {
      fetchData();
    }
  }, [fetchData, isIdEmpty]);

  const handleChange = (fieldName) => (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: e.value,
    }));
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const {
        yearlyCarbonEmissionsId,
        farmId,
        farmLivestockTypeId,
        animalCount,
        livestockLiveResidenceTime,
        livestockManureStorageTime,
        manureStorageAreaDimensions,
        averageLivestockLiveWeight,
        operatingCapacity,
        fertilizerIntakeFrequency,
        farmOfficerName,
        farmOfficerTel,
        farmManureReceivedRate,directNitrogenMonoxideEmissions,inDirectNitrogenMonoxideEmissions
      } = formData;

      try {
        setLoading(true);
        const result = await save(
          id,
          yearlyCarbonEmissionsId,
          farmId,
          farmLivestockTypeId,
          animalCount,
          livestockLiveResidenceTime,
          livestockManureStorageTime,
          manureStorageAreaDimensions,
          averageLivestockLiveWeight,
          operatingCapacity,
          fertilizerIntakeFrequency,
          farmOfficerName,
          farmOfficerTel,
          farmManureReceivedRate,directNitrogenMonoxideEmissions,inDirectNitrogenMonoxideEmissions
        );

        if (!result.isOk) {
          notify(result.message, "error", 2000);
        } else {
          notify(result.message, "success", 2000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setLoading(false);
      }
    },
    [id, formData]
  );

  const farmSelectBoxList = useSelector(
    (state) => state.selectBoxLists.farmSelectBoxList
  );
  const yearlyCarbonEmissionsSelectBoxList = useSelector(
    (state) => state.selectBoxLists.yearlyCarbonEmissionsSelectBoxList
  );
  const farmLivestockTypeSelectBoxList = useSelector(
    (state) => state.selectBoxLists.farmLivestockTypeSelectBoxList
  );
  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <h2 className={"content-block"}>
            Farm Survey {isIdEmpty ? "Save" : "Update"} Form
          </h2>
          <form className={"login-form"} onSubmit={onSubmit}>
            <Form formData={formData} disabled={loading}>
              <GroupItem colCount={3}>
                <Item
                  dataField={"yearlyCarbonEmissionsId"}
                  editorType={"dxSelectBox"}
                  editorOptions={{
                    items: yearlyCarbonEmissionsSelectBoxList,
                    displayExpr: "text",
                    valueExpr: "value",
                    stylingMode: "filled",
                    searchEnabled: true,
                    onValueChanged: handleChange("yearlyCarbonEmissionsId"),
                  }}
                >
                  <RequiredRule message="YearlyCarbonEmissions is Required." />
                  <Label visible={true} text="Yearly CarbonEmissions" />
                </Item>
                <Item
                  dataField={"farmId"}
                  editorType={"dxSelectBox"}
                  editorOptions={{
                    items: farmSelectBoxList,
                    displayExpr: "text",
                    valueExpr: "value",
                    stylingMode: "filled",
                    searchEnabled: true,
                    onValueChanged: handleChange("farmId"),
                  }}
                >
                  <RequiredRule message="Farm is Required." />
                  <Label visible={true} text="Farm" />
                </Item>
                <Item
                  dataField={"farmLivestockTypeId"}
                  editorType={"dxSelectBox"}
                  editorOptions={{
                    items: farmLivestockTypeSelectBoxList,
                    displayExpr: "text",
                    valueExpr: "value",
                    stylingMode: "filled",
                    searchEnabled: true,
                    onValueChanged: handleChange("farmLivestockTypeId"),
                  }}
                >
                  <RequiredRule message="Farm Livestock Type is Required." />
                  <Label visible={true} text="Farm Livestock Type" />
                </Item>
              </GroupItem>
              <GroupItem colCount={3}>
                <Item
                  dataField={"animalCount"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Animal Count",
                    format: "##0.####",
                    onValueChanged: handleChange("animalCount"),
                  }}
                >
                  <RequiredRule message="Animal Count is Required." />
                  <Label visible={true} text="Animal Count" />
                </Item>
                <Item
                  dataField={"livestockLiveResidenceTime"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Livestock Live Residence Time",
                    format: "##0.####",
                    onValueChanged: handleChange("livestockLiveResidenceTime"),
                  }}
                >
                  <RequiredRule message="Livestock Live Residence Time is Required." />
                  <Label visible={true} text="Livestock Live Residence Time" />
                </Item>
                <Item
                  dataField={"livestockManureStorageTime"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Livestock Manure Storage Time",
                    format: "##0.####",
                    onValueChanged: handleChange("livestockManureStorageTime"),
                  }}
                >
                  <RequiredRule message="Livestock Manure Storage Time is Required." />
                  <Label visible={true} text="Livestock Manure Storage Time" />
                </Item>
              </GroupItem>
              <GroupItem colCount={1}>
                <Item
                  dataField={"manureStorageAreaDimensions"}
                  editorType={"dxTextBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Manure Storage Area Dimensions",
                    onValueChanged: handleChange("manureStorageAreaDimensions"),
                  }}
                >
                  <RequiredRule message="Manure Storage Area Dimensions is Required." />
                  <Label visible={true} text="Manure Storage Area Dimensions" />
                </Item>
              </GroupItem>
              <GroupItem colCount={2}>
                <Item
                  dataField={"averageLivestockLiveWeight"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Average Livestock Live Weight",
                    format: "##0.####",
                    onValueChanged: handleChange("averageLivestockLiveWeight"),
                  }}
                >
                  <RequiredRule message="Average Livestock Live Weight is Required." />
                  <Label visible={true} text="Average Livestock Live Weight" />
                </Item>
                <Item
                  dataField={"operatingCapacity"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Operating Capacity",
                    format: "##0.####",
                    onValueChanged: handleChange("operatingCapacity"),
                  }}
                >
                  <RequiredRule message="Operating Capacity is Required." />
                  <Label visible={true} text="Operating Capacity" />
                </Item>
              </GroupItem>
              <GroupItem colCount={2}>
                <Item
                  dataField={"fertilizerIntakeFrequency"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Fertilizer Intake Frequency",
                    format: "##0.####",
                    onValueChanged: handleChange("fertilizerIntakeFrequency"),
                  }}
                >
                  <RequiredRule message="Fertilizer Intake Frequency is Required." />
                  <Label visible={true} text="Fertilizer Intake Frequency" />
                </Item>
                <Item
                  dataField={"farmManureReceivedRate"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Farm Manure Received Rate",
                    format: "##0.####",
                    onValueChanged: handleChange("farmManureReceivedRate"),
                  }}
                >
                  <RequiredRule message="Farm Manure Received Rate is Required." />
                  <Label visible={true} text="Farm Manure Received Rate" />
                </Item>
              </GroupItem>
              <GroupItem colCount={2}>
                <Item
                  dataField={"farmOfficerName"}
                  editorType={"dxTextBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Farm Officer Name",
                    onValueChanged: handleChange("farmOfficerName"),
                  }}
                >
                  <RequiredRule message="Farm Officer Name is Required." />
                  <Label visible={true} text="Farm Officer Name" />
                </Item>
                <Item
                  dataField={"farmOfficerTel"}
                  editorType={"dxTextBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    mask: "+\\9\\0 (000) 000-0000",
                    placeholder: "Farm Officer Tel",
                    onValueChanged: handleChange("farmOfficerTel"),
                  }}
                >
                  <RequiredRule message="Farm Officer Tel is Required." />
                  <Label visible={true} text="Farm Officer Tel" />
                </Item>
              </GroupItem>
              <ButtonItem>
                <ButtonOptions
                  width={"100%"}
                  type="normal"
                  stylingMode="outlined"
                  useSubmitBehavior={true}
                >
                  <span className="dx-button-text">
                    {loading ? (
                      <LoadIndicator
                        width={"24px"}
                        height={"24px"}
                        visible={true}
                      />
                    ) : (
                      "Kaydet"
                    )}
                  </span>
                </ButtonOptions>
              </ButtonItem>
            </Form>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}
