import axios from "axios";

export async function save(
  id,  
  entryDate,
  tonnage,
) {
  try {    
    var res = await axios.post(
      `${process.env.REACT_APP_API_URL}/liquidFermentedFertilizerWeightbridgeData/save`,
      {
        id,      
        entryDate,
        tonnage,      
      }
    );

    return {
      isOk: true,
      data: res.data.data,
      message:res.data.message
    };
  } catch (error) {    
      console.log(res);
    return {
      isOk: false,
      message: "Kayıt işlemi başarısız.",
    };
  }
}

export async function getById(id) {
  try {
    var res = await axios.get(
      `${process.env.REACT_APP_API_URL}/liquidFermentedFertilizerWeightbridgeData/getbyid?id=${id}`
    );

    return {
      isOk: true,
      data: res.data,
    };
  } catch (error) {
    return {
      isOk: false,
      message: "İşlem başarısız.",
    };
  }
}
