import { createSlice } from "@reduxjs/toolkit";

const defaultUserModel = {
  firstName: "",
  lastName: "",
  email: "user@mail.com",
  status: false,
  roles: null, 
};
const localStorageUser=localStorage.getItem('btys2_user');
const initialState = {
  user: JSON.parse(localStorageUser)??defaultUserModel,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      localStorage.setItem('btys2_user', JSON.stringify(action.payload)); 
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
