import React, { useState, useCallback } from "react";
import TabPanel, { Item } from "devextreme-react/tab-panel";
import List from "devextreme-react/list";
import { yearlyCarbonEmissionsDataStore } from "../../api/dxDataStores";
import { Button } from "devextreme-react";
import Chart, {
  CommonSeriesSettings,
  Series,
  Export as ChartExport,
  Legend,
  Tooltip,
  Title,
  Grid,
  Label,
  Format,
  ArgumentAxis,
  Margin,
  Subtitle,
} from "devextreme-react/chart";
import { getBEyAMCalc } from "../../api/yearlyCarbonEmissions";
import notify from "devextreme/ui/notify";
import CalcDataGridComponent from "./calcDataGridComponent";

export default function BEyAMCalc() {
  const [loading, setLoading] = useState(false);
  const [selectedItemKeys, setSelectedItemKeys] = React.useState([]);
  const onSelectedItemKeysChange = React.useCallback(
    (e) => {
      const addedItems = e.addedItems || [];
      const removedItems = e.removedItems || [];
      const updatedSelectedItems = [...selectedItemKeys, ...addedItems].filter(
        (item) =>
          !removedItems.find((removedItem) => removedItem.id === item.id)
      );

      setSelectedItemKeys(updatedSelectedItems);
    },
    [selectedItemKeys, setSelectedItemKeys]
  );
  const [calculationData, setCalculationData] = useState({});

  const onCalculateClick = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getBEyAMCalc(selectedItemKeys);

      if (result.isOk) {
        setCalculationData(result.data);
      } else {
        notify(result.message, "error", 2000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [selectedItemKeys]);

  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <h2 className={"content-block"}>BEyAMS0073 Calculation</h2>
          <div className="view-wrapper view-wrapper-details">
            <div className="panels">
              <div className="left">
                <List
                  dataSource={yearlyCarbonEmissionsDataStore}
                  height={300}
                  valueExpr="id"
                  displayExpr="title"
                  showSelectionControls={true}
                  selectionMode="all"
                  selectAllMode="allPages"
                  selectByClick={true}
                  onSelectionChanged={onSelectedItemKeysChange}
                ></List>
                <Button
                  icon="check"
                  type="default"
                  text="Calculate"
                  onClick={onCalculateClick}
                />
              </div>
              <div className="right">
                <TabPanel>
                  <Item title="Beyam0073summary">
                    <Chart
                      id="chart"
                      palette="Soft"
                      dataSource={calculationData.beyam0073summaryList || []}
                    >
                      <CommonSeriesSettings
                        argumentField="year"
                        type="bar"
                        ignoreEmptyPoints={true}
                      />
                      <ArgumentAxis
                        allowDecimals={false}
                        axisDivisionFactor={60}
                      >
                        <Label>
                          <Format type="decimal" />
                        </Label>
                      </ArgumentAxis>
                      <Series valueField="ery" name="ery" />
                      <Series valueField="bey" name="bey" />
                      <Series valueField="pey" name="pey" />
                      <Series valueField="ley" name="ley" />

                      <Legend
                        verticalAlignment="bottom"
                        horizontalAlignment="center"
                      />
                      <ChartExport enabled={true} />
                      <Title text="Calculation Charts">
                        <Subtitle text="(BeyamsIIID Summary)" />
                      </Title>
                    </Chart>
                    <CalcDataGridComponent
                      calcList={calculationData.beyam0073summaryList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "ery",
                          caption: "Ery",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "bey",
                          caption: "Bey",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "pey",
                          caption: "Pey",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "ley",
                          caption: "Ley",
                          dataType: "double",
                        },
                      ]}
                      excelName="beyam0073summaryList"
                    />
                  </Item>
                  <Item title="Beyamsiiidsummary">
                    <CalcDataGridComponent
                      calcList={calculationData.beyamsiiidsummaryList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "beyamsiiid",
                          caption: "Beyamsiiid",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "beyamsid",
                          caption: "Beyamsid",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "peply",
                          caption: "Peply",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "peflarey",
                          caption: "Peflarey",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "pepowery",
                          caption: "Pepowery",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 6,
                          dataField: "petranspy",
                          caption: "Petranspy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 7,
                          dataField: "pestoragey",
                          caption: "Pestoragey",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 8,
                          dataField: "lestorageliquidy",
                          caption: "Lestorageliquidy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 9,
                          dataField: "lestoragesolidy",
                          caption: "Lestoragesolidy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 10,
                          dataField: "ery",
                          caption: "Ery",
                          dataType: "double",
                        },
                      ]}
                      excelName="beyamsiiidsummaryList"
                    />
                  </Item>
                  <Item title="Vslty">
                    <CalcDataGridComponent
                      calcList={calculationData.vsltyList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "farmLivestockTypeId",
                          caption: "farmLivestockTypeId",
                          dataType: "Guid",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "vslty",
                          caption: "Vslty",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "wsite",
                          caption: "Wsite",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "wdefault",
                          caption: "Wdefault",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "wsitedividedefault",
                          caption: "Wsitedividedefault",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 6,
                          dataField: "vsdefault",
                          caption: "Vsdefault",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 7,
                          dataField: "ndy",
                          caption: "Ndy",
                          dataType: "double",
                        },
                      ]}
                      excelName="vsltyList"
                    />
                  </Item>

                  <Item title="Beyamsiiid">
                    <CalcDataGridComponent
                      calcList={calculationData.beyamsiiidList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "farmLivestockTypeId",
                          caption: "farmLivestockTypeId",
                          dataType: "Guid",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "beyamsiiid",
                          caption: "Beyamsiiid",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "gwpch4",
                          caption: "GWP CH4",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "dch4",
                          caption: "D CH4",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "ufb",
                          caption: "UFB",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 6,
                          dataField: "mcFj",
                          caption: "MCFj",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 7,
                          dataField: "b0lt",
                          caption: "B0lt",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 8,
                          dataField: "nlty",
                          caption: "Nlty",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 9,
                          dataField: "vslty",
                          caption: "Vslty",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 10,
                          dataField: "mspblj",
                          caption: "Mspblj",
                          dataType: "double",
                        },
                      ]}
                      excelName="beyamsiiidList"
                    />
                  </Item>

                  <Item title="Efgridy">
                    <CalcDataGridComponent
                      calcList={calculationData.efgridyList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "efgridy",
                          caption: "Efgridy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "efgridomy",
                          caption: "Efgridomy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "wom",
                          caption: "Wom",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "efgridbmy",
                          caption: "Efgridbmy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "wbm",
                          caption: "Wbm",
                          dataType: "double",
                        },
                      ]}
                      excelName="efgridyList"
                    />
                  </Item>

                  <Item title="Beyamsid">
                    <CalcDataGridComponent
                      calcList={calculationData.beyamsidList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "beyamsid",
                          caption: "Beyamsid",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "egpjy",
                          caption: "Egpjy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "efgridy",
                          caption: "Efgridy",
                          dataType: "double",
                        },
                      ]}
                      excelName="beyamsidList"
                    />
                  </Item>

                  <Item title="Coeflinyity">
                    <CalcDataGridComponent
                      calcList={calculationData.coeflinyityList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "coeflinyity",
                          caption: "Coeflinyity",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "ncvlinyity",
                          caption: "Ncvlinyity",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "efco2iy",
                          caption: "Efco2iy",
                          dataType: "double",
                        },
                      ]}
                      excelName="coeflinyityList"
                    />
                  </Item>
                  <Item title="Pefcylinyit">
                    <CalcDataGridComponent
                      calcList={calculationData.pefcylinyitList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "pefcylinyit",
                          caption: "Pefcylinyit",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "fclinyitjy",
                          caption: "Fclinyitjy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "coeflinyity",
                          caption: "Coeflinyity",
                          dataType: "double",
                        },
                      ]}
                      excelName="pefcylinyitList"
                    />
                  </Item>
                  <Item title="Peecy">
                    <CalcDataGridComponent
                      calcList={calculationData.peecyList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "peecy",
                          caption: "Peecy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "ecpjjy",
                          caption: "Ecpjjy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "efefjy",
                          caption: "Efefjy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "tdljyplusone",
                          caption: "Tdljyplusone",
                          dataType: "double",
                        },
                      ]}
                      excelName="peecyList"
                    />
                  </Item>

                  <Item title="Pepowery">
                    <CalcDataGridComponent
                      calcList={calculationData.pepoweryList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "pepowery",
                          caption: "Pepowery",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "peecy",
                          caption: "Peecy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "pefcylinyit",
                          caption: "Pefcylinyit",
                          dataType: "double",
                        },
                      ]}
                      excelName="pepoweryList"
                    />
                  </Item>
                  <Item title="Petranspy">
                    <CalcDataGridComponent
                      calcList={calculationData.petranspyList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "petranspy",
                          caption: "Petranspy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "qy",
                          caption: "Qy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "cty",
                          caption: "Cty",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "qycty",
                          caption: "Qycty",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "dafw",
                          caption: "Dafw",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 6,
                          dataField: "efco2transport",
                          caption: "Efco2transport",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 7,
                          dataField: "qreswastey",
                          caption: "Qreswastey",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 8,
                          dataField: "ctreswastey",
                          caption: "Ctreswastey",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 9,
                          dataField: "qreswasteyctreswastey",
                          caption: "Qreswasteyctreswastey",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 10,
                          dataField: "dafreswaste",
                          caption: "Dafreswaste",
                          dataType: "double",
                        },
                      ]}
                      excelName="petranspyList"
                    />
                  </Item>

                  <Item title="Pestoragey">
                    <CalcDataGridComponent
                      calcList={calculationData.pestorageyList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "farmLivestockTypeId",
                          caption: "farmLivestockTypeId",
                          dataType: "Guid",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "pestoragey",
                          caption: "Pestoragey",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "gwpch4",
                          caption: "GWP CH4",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "dch4",
                          caption: "D CH4",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "allweightedaverage",
                          caption: "Allweightedaverage",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 6,
                          dataField: "nltsum",
                          caption: "Nltsum",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 7,
                          dataField: "vsltdweightedaverage",
                          caption: "Vsltdweightedaverage",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 8,
                          dataField: "mslweightedaverage",
                          caption: "Mslweightedaverage",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 9,
                          dataField: "decompositionformula",
                          caption: "Decompositionformula",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 10,
                          dataField: "day",
                          caption: "Day",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 11,
                          dataField: "mcFj",
                          caption: "MCFj",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 12,
                          dataField: "b0ltweightedaverage",
                          caption: "B0ltweightedaverage",
                          dataType: "double",
                        },
                      ]}
                      excelName="pestorageyList"
                    />
                  </Item>
                  <Item title="Peflarey">
                    <CalcDataGridComponent
                      calcList={calculationData.peflareyList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "peflarey",
                          caption: "Peflarey",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "gwpch4",
                          caption: "GWP CH4",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "fch4rgm",
                          caption: "Fch4rgm",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "dch4",
                          caption: "D CH4",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "nflarem",
                          caption: "Nflarem",
                          dataType: "double",
                        },
                      ]}
                      excelName="peflareyList"
                    />
                  </Item>

                  <Item title="Peply">
                    <CalcDataGridComponent
                      calcList={calculationData.peplyList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "peply",
                          caption: "Peply",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "qch4y",
                          caption: "Qch4y",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "efch4default",
                          caption: "Efch4default",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "gwpch4",
                          caption: "GWP CH4",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "dch4",
                          caption: "D CH4",
                          dataType: "double",
                        },
                      ]}
                      excelName="peplyList"
                    />
                  </Item>

                  <Item title="Peyamsiiid">
                    <CalcDataGridComponent
                      calcList={calculationData.peyamsiiidList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "peyamsiiid",
                          caption: "Peyamsiiid",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "peply",
                          caption: "Peply",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "peflarey",
                          caption: "Peflarey",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "pepowery",
                          caption: "Pepowery",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "petranspy",
                          caption: "Petranspy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 6,
                          dataField: "pestoragey",
                          caption: "Pestoragey",
                          dataType: "double",
                        },
                      ]}
                      excelName="peyamsiiidList"
                    />
                  </Item>

                  <Item title="Leady">
                    <CalcDataGridComponent
                      calcList={calculationData.leadyList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "leady",
                          caption: "Leady",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "lestorageliquidy",
                          caption: "Lestorageliquidy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "lestoragesolidy",
                          caption: "Lestoragesolidy",
                          dataType: "double",
                        },
                      ]}
                      excelName="leadyList"
                    />
                  </Item>
                  <Item title="Lestorageliquidy">
                    <CalcDataGridComponent
                      calcList={calculationData.lestorageliquidyList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "lestorageliquidy",
                          caption: "Lestorageliquidy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "fwwch4default",
                          caption: "Fwwch4default",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "qch4y",
                          caption: "Qch4y",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "gwpch4",
                          caption: "GWP CH4",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "dch4",
                          caption: "D CH4",
                          dataType: "double",
                        },
                      ]}
                      excelName="lestorageliquidyList"
                    />
                  </Item>

                  <Item title="Lestoragesolidy">
                    <CalcDataGridComponent
                      calcList={calculationData.lestoragesolidyList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "lestoragesolidy",
                          caption: "Lestoragesolidy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "fsdch4default",
                          caption: "Fsdch4default",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "qch4y",
                          caption: "Qch4y",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "gwpch4",
                          caption: "GWP CH4",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "dch4",
                          caption: "D CH4",
                          dataType: "double",
                        },
                      ]}
                      excelName="lestoragesolidyList"
                    />
                  </Item>

                  <Item title="Eryamsiiid">
                    <CalcDataGridComponent
                      calcList={calculationData.eryamsiiidList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "eryexpost",
                          caption: "Eryexpost",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "beyamsiiid",
                          caption: "Beyamsiiid",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "peyamsiiid",
                          caption: "Peyamsiiid",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "mdy",
                          caption: "Mdy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "pepowery",
                          caption: "Pepowery",
                          dataType: "double",
                        },
                      ]}
                      excelName="eryamsiiidList"
                    />
                  </Item>

                  <Item title="Eryamsid">
                    <CalcDataGridComponent
                      calcList={calculationData.eryamsidList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "eryamsid",
                          caption: "Eryamsid",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "beyamsid",
                          caption: "Beyamsid",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "peyamsid",
                          caption: "Peyamsid",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "leyamsid",
                          caption: "Leyamsid",
                          dataType: "double",
                        },
                      ]}
                      excelName="eryamsidList"
                    />
                  </Item>

                  <Item title="Ery">
                    <CalcDataGridComponent
                      calcList={calculationData.eryList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "ery",
                          caption: "Ery",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "beyamsiiid",
                          caption: "Beyamsiiid",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "beyamsid",
                          caption: "Beyamsid",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "peyamsiiid",
                          caption: "Peyamsiiid",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "leyamsiiid",
                          caption: "Leyamsiiid",
                          dataType: "double",
                        },
                      ]}
                      excelName="eryList"
                    />
                  </Item>
                  <Item title="Beyam0073">
                    <CalcDataGridComponent
                      calcList={calculationData.beyam0073List || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "beyam0073",
                          caption: "Beyam0073",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "beawy",
                          caption: "Beawy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "beelecheaty",
                          caption: "Beelecheaty",
                          dataType: "double",
                        },
                      ]}
                      excelName="beyam0073List"
                    />
                  </Item>

                  <Item title="Beawy">
                    <CalcDataGridComponent
                      calcList={calculationData.beawyList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "beawy",
                          caption: "Beawy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "beawch4y",
                          caption: "Beawch4y",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "beawn2oy",
                          caption: "Beawn2oy",
                          dataType: "double",
                        },
                      ]}
                      excelName="beawyList"
                    />
                  </Item>

                  <Item title="Beawch4y">
                    <CalcDataGridComponent
                      calcList={calculationData.beawch4yList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "farmLivestockTypeId",
                          caption: "farmLivestockTypeId",
                          dataType: "Guid",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "beawch4y",
                          caption: "Beawch4y",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "gwpch4",
                          caption: "GWP CH4",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "dch4",
                          caption: "D CH4",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "mcFj",
                          caption: "MCFj",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 6,
                          dataField: "b0lt",
                          caption: "B0lt",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 7,
                          dataField: "nlty",
                          caption: "Nlty",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 8,
                          dataField: "vslty",
                          caption: "Vslty",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 9,
                          dataField: "mspblj",
                          caption: "Mspblj",
                          dataType: "double",
                        },
                      ]}
                      excelName="beawch4yList"
                    />
                  </Item>

                  <Item title="Beawn2oy">
                    <CalcDataGridComponent
                      calcList={calculationData.beawn2oyList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "beawn2oy",
                          caption: "Beawn2oy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "gwpn2o",
                          caption: "GWP N2O",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "cfn2onn",
                          caption: "CF N2ONN",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "en2ody",
                          caption: "EN2ODY",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "en2oidy",
                          caption: "EN2OIDY",
                          dataType: "double",
                        },
                      ]}
                      excelName="beawn2oyList"
                    />
                  </Item>
                  <Item title="Beelecheaty">
                    <CalcDataGridComponent
                      calcList={calculationData.beelecheatyList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "beelecheaty",
                          caption: "Beelecheaty",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "egbly",
                          caption: "Egbly",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "cefblelecy",
                          caption: "Cefblelecy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "egdy",
                          caption: "Egdy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "cefgrid",
                          caption: "Cefgrid",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 6,
                          dataField: "hgbly",
                          caption: "Hgbly",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 7,
                          dataField: "cefblthermy",
                          caption: "Cefblthermy",
                          dataType: "double",
                        },
                      ]}
                      excelName="beelecheatyList"
                    />
                  </Item>

                  <Item title="Peyam0073">
                    <CalcDataGridComponent
                      calcList={calculationData.peyam0073List || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "peyam0073",
                          caption: "Peyam0073",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "peady",
                          caption: "Peady",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "peaery",
                          caption: "Peaery",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "pecompy",
                          caption: "Pecompy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "pen2oy",
                          caption: "Pen2oy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 6,
                          dataField: "peply",
                          caption: "Peply",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 7,
                          dataField: "peflarey",
                          caption: "Peflarey",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 8,
                          dataField: "peelecheaty",
                          caption: "Peelecheaty",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 9,
                          dataField: "peco2transy",
                          caption: "Peco2transy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 10,
                          dataField: "pestoragey",
                          caption: "Pestoragey",
                          dataType: "double",
                        },
                      ]}
                      excelName="peyam0073List"
                    />
                  </Item>
                  <Item title="Peady">
                    <CalcDataGridComponent
                      calcList={calculationData.peadyList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "peady",
                          caption: "Peady",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "gwpch4",
                          caption: "GWP CH4",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "dch4",
                          caption: "D CH4",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "lfad",
                          caption: "Lfad",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "lfadminusone",
                          caption: "Lfadminusone",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 6,
                          dataField: "lfaddividelfadminusone",
                          caption: "Lfaddividelfadminusone",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 7,
                          dataField: "fvrgh",
                          caption: "Fvrgh",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 8,
                          dataField: "fvch4rgh",
                          caption: "Fvch4rgh",
                          dataType: "double",
                        },
                      ]}
                      excelName="peadyList"
                    />
                  </Item>

                  <Item title="Pen2oy">
                    <CalcDataGridComponent
                      calcList={calculationData.pen2oyList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "pen2oy",
                          caption: "Pen2oy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "gwpn2o",
                          caption: "GWP N2O",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "cfn2onn",
                          caption: "CF N2ONN",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "efn2ody",
                          caption: "EFN2ODY",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "efn2oidy",
                          caption: "EFN2OIDY",
                          dataType: "double",
                        },
                      ]}
                      excelName="pen2oyList"
                    />
                  </Item>

                  <Item title="Efn2ody">
                    <CalcDataGridComponent
                      calcList={calculationData.efn2odyList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "month",
                          caption: "month",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "monthStr",
                          caption: "month",
                          dataType: "string",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "efn2ody",
                          caption: "EFN2ODY",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "efn2odn",
                          caption: "EFN2ODN",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "oemm",
                          caption: "OEMM",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 6,
                          dataField: "nemm",
                          caption: "NEMM",
                          dataType: "double",
                        },
                      ]}
                      excelName="efn2odyList"
                    />
                  </Item>
                  <Item title="Efn2oidy">
                    <CalcDataGridComponent
                      calcList={calculationData.efn2oidyList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "month",
                          caption: "month",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "monthStr",
                          caption: "month",
                          dataType: "string",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "efn2oidy",
                          caption: "EFN2OIDY",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "efn2oidn",
                          caption: "EFN2OIDN",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "fgasmj",
                          caption: "Fgasmj",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 6,
                          dataField: "oemm",
                          caption: "OEMM",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 7,
                          dataField: "nemm",
                          caption: "NEMM",
                          dataType: "double",
                        },
                      ]}
                      excelName="efn2oidyList"
                    />
                  </Item>

                  <Item title="Peelecheaty">
                    <CalcDataGridComponent
                      calcList={calculationData.peelecheatyList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "peelecheaty",
                          caption: "Peelecheaty",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "peelecy",
                          caption: "Peelecy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "peheatjy",
                          caption: "Peheatjy",
                          dataType: "double",
                        },
                      ]}
                      excelName="peelecheatyList"
                    />
                  </Item>

                  <Item title="Peco2transy">
                    <CalcDataGridComponent
                      calcList={calculationData.peco2transyList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "peco2transy",
                          caption: "Peco2transy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "nvehiclesjy",
                          caption: "Nvehiclesjy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "distiy",
                          caption: "Distiy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "fcif",
                          caption: "Fcif",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "ncvf",
                          caption: "Ncvf",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 6,
                          dataField: "dmotorin",
                          caption: "Dmotorin",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 7,
                          dataField: "efco2f",
                          caption: "Efco2f",
                          dataType: "double",
                        },
                      ]}
                      excelName="peco2transyList"
                    />
                  </Item>
                  <Item title="Leyam0073">
                    <CalcDataGridComponent
                      calcList={calculationData.leyam0073List || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "leyam0073",
                          caption: "Leyam0073",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "lepn2o",
                          caption: "Lepn2o",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "lebn2o",
                          caption: "Lebn2o",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "lepch4",
                          caption: "Lepch4",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "lebch4",
                          caption: "Lebch4",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 6,
                          dataField: "leco2transy",
                          caption: "Leco2transy",
                          dataType: "double",
                        },
                      ]}
                      excelName="leyam0073List"
                    />
                  </Item>

                  <Item title="Leco2transy">
                    <CalcDataGridComponent
                      calcList={calculationData.leco2transyList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "leco2transy",
                          caption: "Leco2transy",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "efco2f",
                          caption: "Efco2f",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "fcif",
                          caption: "Fcif",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "ncvf",
                          caption: "Ncvf",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "dmotorin",
                          caption: "Dmotorin",
                          dataType: "double",
                        },
                      ]}
                      excelName="leco2transyList"
                    />
                  </Item>

                  <Item title="Lebn2o">
                    <CalcDataGridComponent
                      calcList={calculationData.lebn2oList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "lebn2o",
                          caption: "Lebn2o",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "gwpn2o",
                          caption: "GWP N2O",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "cfn2onn",
                          caption: "CF N2ONN",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "lebn2oland",
                          caption: "Lebn2oland",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "lebn2orunoff",
                          caption: "Lebn2orunoff",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 6,
                          dataField: "lebn2ovol",
                          caption: "Lebn2ovol",
                          dataType: "double",
                        },
                      ]}
                      excelName="lebn2oList"
                    />
                  </Item>
                  <Item title="Lebn2oland">
                    <CalcDataGridComponent
                      calcList={calculationData.lebn2olandList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "farmLivestockTypeId",
                          caption: "farmLivestockTypeId",
                          dataType: "Guid",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "lebn2oland",
                          caption: "Lebn2oland",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "ef1",
                          caption: "Ef1",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "rnn",
                          caption: "Rnn",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "nlty",
                          caption: "Nlty",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 6,
                          dataField: "nexlty",
                          caption: "Nexlty",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 7,
                          dataField: "mspblj",
                          caption: "Mspblj",
                          dataType: "double",
                        },
                      ]}
                      excelName="lebn2olandList"
                    />
                  </Item>

                  <Item title="Lebn2orunoff">
                    <CalcDataGridComponent
                      calcList={calculationData.lebn2orunoffList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "farmLivestockTypeId",
                          caption: "farmLivestockTypeId",
                          dataType: "Guid",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "lebn2orunoff",
                          caption: "Lebn2orunoff",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "ef5",
                          caption: "Ef5",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "fleach",
                          caption: "Fleach",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "rnn",
                          caption: "Rnn",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 6,
                          dataField: "nlty",
                          caption: "Nlty",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 7,
                          dataField: "nexlty",
                          caption: "Nexlty",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 8,
                          dataField: "mspblj",
                          caption: "Mspblj",
                          dataType: "double",
                        },
                      ]}
                      excelName="lebn2orunoffList"
                    />
                  </Item>

                  <Item title="Lebn2ovol">
                    <CalcDataGridComponent
                      calcList={calculationData.lebn2ovolList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "farmLivestockTypeId",
                          caption: "farmLivestockTypeId",
                          dataType: "Guid",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "lebn2ovol",
                          caption: "Lebn2ovol",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "ef4",
                          caption: "Ef4",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "fgasm",
                          caption: "Fgasm",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "rnn",
                          caption: "Rnn",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 6,
                          dataField: "nlty",
                          caption: "Nlty",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 7,
                          dataField: "nexlty",
                          caption: "Nexlty",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 8,
                          dataField: "mspblj",
                          caption: "Mspblj",
                          dataType: "double",
                        },
                      ]}
                      excelName="lebn2ovolList"
                    />
                  </Item>
                  <Item title="Lepn2o">
                    <CalcDataGridComponent
                      calcList={calculationData.lepn2oList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "lepn2o",
                          caption: "Lepn2o",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "gwpn2o",
                          caption: "GWP N2O",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "cfn2onn",
                          caption: "CF N2ONN",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "lepn2oland",
                          caption: "Lepn2oland",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "lepn2orunoff",
                          caption: "Lepn2orunoff",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 6,
                          dataField: "lepn2ovol",
                          caption: "Lepn2ovol",
                          dataType: "double",
                        },
                      ]}
                      excelName="lepn2oList"
                    />
                  </Item>

                  <Item title="Lepn2oland">
                    <CalcDataGridComponent
                      calcList={calculationData.lepn2olandList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "month",
                          caption: "month",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "monthStr",
                          caption: "month",
                          dataType: "string",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "lep2oland",
                          caption: "Lep2oland",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "ef1",
                          caption: "Ef1",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "qdem",
                          caption: "Qdem",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 6,
                          dataField: "ndem",
                          caption: "Ndem",
                          dataType: "double",
                        },
                      ]}
                      excelName="lepn2olandList"
                    />
                  </Item>

                  <Item title="Lepn2orunoff">
                    <CalcDataGridComponent
                      calcList={calculationData.lepn2orunoffList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "month",
                          caption: "month",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "monthStr",
                          caption: "month",
                          dataType: "string",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "lep2orunoff",
                          caption: "Lep2orunoff",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "ef5",
                          caption: "Ef5",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "fleach",
                          caption: "Fleach",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 6,
                          dataField: "qdem",
                          caption: "Qdem",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 7,
                          dataField: "ndem",
                          caption: "Ndem",
                          dataType: "double",
                        },
                      ]}
                      excelName="lepn2orunoffList"
                    />
                  </Item>
                  <Item title="Lepn2ovol">
                    <CalcDataGridComponent
                      calcList={calculationData.lepn2ovolList || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "month",
                          caption: "month",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "monthStr",
                          caption: "month",
                          dataType: "string",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "lep2ovol",
                          caption: "Lep2ovol",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "ef4",
                          caption: "Ef4",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "fgasm",
                          caption: "Fgasm",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 6,
                          dataField: "qdem",
                          caption: "Qdem",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 7,
                          dataField: "ndem",
                          caption: "Ndem",
                          dataType: "double",
                        },
                      ]}
                      excelName="lepn2ovolList"
                    />
                  </Item>
                  <Item title="Lebch4">
                    <CalcDataGridComponent
                      calcList={calculationData.lebch4List || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "farmLivestockTypeId",
                          caption: "farmLivestockTypeId",
                          dataType: "Guid",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "lebch4",
                          caption: "Lebch4",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "gwpch4",
                          caption: "GWP CH4",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "dch4",
                          caption: "D CH4",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "mcfd",
                          caption: "Mcfd",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 6,
                          dataField: "rvsn",
                          caption: "Rvsn",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 7,
                          dataField: "b0lt",
                          caption: "B0lt",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 8,
                          dataField: "nlty",
                          caption: "Nlty",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 9,
                          dataField: "vslty",
                          caption: "Vslty",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 10,
                          dataField: "mspblj",
                          caption: "Mspblj",
                          dataType: "double",
                        },
                      ]}
                      excelName="lebch4List"
                    />
                  </Item>
                  <Item title="Lepch4">
                    <CalcDataGridComponent
                      calcList={calculationData.lepch4List || []}
                      columnMap={[
                        {
                          isVisible: true,
                          order: 0,
                          dataField: "farmLivestockTypeId",
                          caption: "farmLivestockTypeId",
                          dataType: "Guid",
                        },
                        {
                          isVisible: true,
                          order: 1,
                          dataField: "year",
                          caption: "year",
                          dataType: "int",
                        },
                        {
                          isVisible: true,
                          order: 2,
                          dataField: "lepch4",
                          caption: "Lepch4",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 3,
                          dataField: "gwpch4",
                          caption: "GWP CH4",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 4,
                          dataField: "dch4",
                          caption: "D CH4",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 5,
                          dataField: "mcfd",
                          caption: "Mcfd",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 6,
                          dataField: "qdem",
                          caption: "Qdem",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 7,
                          dataField: "ndem",
                          caption: "Ndem",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 8,
                          dataField: "b0lt",
                          caption: "B0lt",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 9,
                          dataField: "nlty",
                          caption: "Nlty",
                          dataType: "double",
                        },
                        {
                          isVisible: true,
                          order: 10,
                          dataField: "vslty",
                          caption: "Vslty",
                          dataType: "double",
                        },
                      ]}
                      excelName="lepch4List"
                    />
                  </Item>
                </TabPanel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
