import React, { useState } from "react";
import {
  Export,
  Item,
  Toolbar,
  ColumnChooser,
  Column,
  Button,
  DataGrid,
  Lookup,
  FilterRow,
  SearchPanel,
  HeaderFilter,
  Paging,
} from "devextreme-react/data-grid";
import { Button as DxButton } from "devextreme-react/button";
import { useNavigate } from "react-router-dom";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import {
  biogasPlantInfoDataStore,
  biogasPlantSurveyDataStore,
  yearlyCarbonEmissionsDataStore,
} from "../../api/dxDataStores";
import { handleDxExcelExport } from "../../utils/dxExcelExportUtils";  // Dosya yolu doğru ise ayarlayın

export default function BiogasPlantSurveys() {
  const navigate = useNavigate();
  function onExporting(e) {
    handleDxExcelExport(e, "BiogasPlantSurvey", "BiogasPlantSurveys.xlsx");
  }

  const onEditClick = (e) => {
    navigate(`/carbonEmissionTracking/biogasPlantSurveys/form/${e.row.data.id}`);
  };
  const onDeleteClick = (e) => {
    console.log("Silme İşlemi");
  };
  const onAddClick = (e) => {
    navigate(
      `/carbonEmissionTracking/biogasPlantSurveys/form/${"00000000-0000-0000-0000-000000000000"}`
    );
  };
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});

  const onDetayClick = (e) => {
    // Kullanıcının tıkladığı satırın verilerini al
    const selectedData = e.row.data;
    // Popup'ı aç ve içeriği güncelle
    setSelectedRowData(selectedData);
    setPopupVisible(true);
  };

  const hidePopup = () => {
    setPopupVisible(false);
  };
  return (
    <>
      <React.Fragment>
        <div className={"content-block"}>
          <div className={"dx-card responsive-paddings"}>
            <h2 className={"content-block"}>Biogas Plant Surveys</h2>
            <DataGrid
              dataSource={biogasPlantSurveyDataStore}
              showBorders={true}
              height={700}
              remoteOperations={true}
              onExporting={onExporting}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
            >
              <Toolbar>
                <Item location="after" locateInMenu="auto">
                  <DxButton icon="plus" onClick={onAddClick} />
                </Item>
                <Item location="after" locateInMenu="auto">
                  <div className="separator" />
                </Item>
                <Item name="exportButton" locateInMenu="auto" />
                <Item location="after" locateInMenu="auto">
                  <div className="separator" />
                </Item>
                <Item name="columnChooserButton" locateInMenu="auto" />
                <Item location="after" locateInMenu="auto">
                  <div className="separator" />
                </Item>
                <Item name="searchPanel" location="after" />
              </Toolbar>
              <ColumnChooser enabled />
              <SearchPanel
                visible={true}
                highlightCaseSensitive={true}
                placeholder="Search"
                width={250}
              />
              <FilterRow visible={true} />
              <HeaderFilter visible={true} />
              <Paging defaultPageSize={15} />
              <Column type="buttons">
                <Button hint="Edit" icon="edit" onClick={onEditClick} />
                <Button hint="Delete" icon="trash" onClick={onDeleteClick} />
                <Button hint="Detay" icon="info" onClick={onDetayClick} />
              </Column>

              <Column dataField="biogasPlantInfoId" caption="Plant Name">
                <Lookup
                  dataSource={biogasPlantInfoDataStore}
                  valueExpr="id"
                  displayExpr="name"
                />
              </Column>
              <Column
                dataField="yearlyCarbonEmissionsId"
                caption="Carbon Emission"
              >
                <Lookup
                  dataSource={yearlyCarbonEmissionsDataStore}
                  valueExpr="id"
                  displayExpr={(item) => {
                    return item ? `${item.title} - (${item.year})` : "";
                  }}
                />
              </Column>
              <Column
                dataField="grossElectricityGeneration"
                caption="Gross Electricity Generation"
                dataType="number"
                format={"##0.####"}
              />
              <Column
                dataField="netElectricityGeneration"
                caption="Net Electricity Generation"
                dataType="number"
                format={"##0.####"}
              />
              <Column
                dataField="electricityConsumption"
                caption="Electricity Consumption"
                dataType="number"
                format={"##0.####"}
              />
              <Column
                dataField="fossilFuelConsumptionsWithoutTransportationOperations"
                caption="Fossil Fuel Consumptions Without Transportation Operations"
                dataType="number"
                format={"##0.####"}
              />
              <Column
                dataField="biogasProduction"
                caption="Biogas Production"
                dataType="number"
                format={"##0.####"}
              />
              <Column
                dataField="biogasMethaneContentAverage"
                caption="Biogas Methane Content Average"
                dataType="number"
                format={"##0.####"}
              />
              <Column
                dataField="methaneProduction"
                caption="Methane Production"
                dataType="number"
                format={"##0.####"}
              />
              <Column
                dataField="manureProcessingAnnualAmount"
                caption="Manure Processing Annual Amount"
                dataType="number"
                format={"##0.####"}
              />
              <Column
                dataField="processedManureTransportedAnnualAmount"
                caption="Processed Manure Transported Annual Amount"
                dataType="number"
                format={"##0.####"}
              />
              <Column
                dataField="farmLandDAF"
                caption="Farm Land DAF"
                dataType="number"
                format={"##0.####"}
              />
              <Column
                dataField="fieldDAF"
                caption="Field DAF"
                dataType="number"
                format={"##0.####"}
              />
              <Column
                dataField="cTy"
                caption="CTy"
                dataType="number"
                format={"##0.####"}
              />
              <Column
                dataField="cTreswastey"
                caption="CTreswastey"
                dataType="number"
                format={"##0.####"}
              />
              <Column
                dataField="flareBurningCapacity"
                caption="Flare Burning Capacity"
                dataType="number"
                format={"##0.####"}
              />
              <Column
                dataField="flareBurningEfficiency"
                caption="Flare Burning Efficiency"
                dataType="number"
                format={"##0.####"}
              />

              <Export enabled={true} allowExportSelectedData={true} />
            </DataGrid>
          </div>
        </div>
      </React.Fragment>
      <Popup
        visible={popupVisible}
        onHiding={hidePopup}
        dragEnabled={true}
        showTitle={true}
        title="Sample Popup"
      >
        <ToolbarItem
          widget="dxButton"
          toolbar="top"
          location="after"
          options={{ icon: "close", onClick: hidePopup }}
        />
        <div>
          <p>Yapım Aşamasındadır.</p>
          <p>cty: {selectedRowData.cTy}</p>
          {/* Diğer veri alanları için benzer şekilde devam edebilirsiniz */}
        </div>
      </Popup>
    </>
  );
}
