import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import LoadIndicator from "devextreme-react/load-indicator";
import Form, {
  GroupItem,
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import { save, getById } from "../../../api/biogasPlantSurvey";
import { useSelector } from "react-redux";
import LabelPopoverTemplate from "../../../components/formComponents/labelPopoverTemplate";

export default function BiogasPlantSurveyForm() {
  const { id } = useParams();
  const isEmptyGuid = (guid) => guid === "00000000-0000-0000-0000-000000000000";

  const [formData, setFormData] = useState({
    id: id ?? "",
    biogasPlantInfoId: "",
    yearlyCarbonEmissionsId: "",
    grossElectricityGeneration: 0,
    netElectricityGeneration: 0,
    electricityConsumption: 0,
    fossilFuelConsumptionsWithoutTransportationOperations: 0,
    biogasProduction: 0,
    biogasMethaneContentAverage: 0,
    methaneProduction: 0,
    manureProcessingAnnualAmount: 0,
    processedManureTransportedAnnualAmount: 0,
    farmLandDAF: 0,
    fieldDAF: 0,
    cTy: 0,
    cTreswastey: 0,
    flareBurningCapacity: 0,
    flareBurningEfficiency: 0,
    fossilFuelConsumptionsForTransportationOperations: 0,
    liquidFermentedFertilizerDryMatterRate: 0,
    ndy: 0,
    tdljy: 0,
    monthlyFeedStockNitrogenConcentrationList: [],
  });

  const [loading, setLoading] = useState(false);
  const isIdEmpty = isEmptyGuid(id);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getById(id);

      if (result.isOk) {
        setFormData(result.data);
      } else {
        notify(result.message, "error", 2000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (!isIdEmpty) {
      fetchData();
    }
  }, [fetchData, isIdEmpty]);

  const handleChange = (fieldName) => (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: e.value,
    }));
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const {
        biogasPlantInfoId,
        yearlyCarbonEmissionsId,
        grossElectricityGeneration,
        netElectricityGeneration,
        electricityConsumption,
        fossilFuelConsumptionsWithoutTransportationOperations,
        biogasProduction,
        biogasMethaneContentAverage,
        methaneProduction,
        manureProcessingAnnualAmount,
        processedManureTransportedAnnualAmount,
        farmLandDAF,
        fieldDAF,
        cTy,
        cTreswastey,
        flareBurningCapacity,
        flareBurningEfficiency,
        fossilFuelConsumptionsForTransportationOperations,
        liquidFermentedFertilizerDryMatterRate,
        ndy,
        tdljy,
        monthlyFeedStockNitrogenConcentrationList,
      } = formData;

      try {
        setLoading(true);
        const result = await save(
          id,
          biogasPlantInfoId,
          yearlyCarbonEmissionsId,
          grossElectricityGeneration,
          netElectricityGeneration,
          electricityConsumption,
          fossilFuelConsumptionsWithoutTransportationOperations,
          biogasProduction,
          biogasMethaneContentAverage,
          methaneProduction,
          manureProcessingAnnualAmount,
          processedManureTransportedAnnualAmount,
          farmLandDAF,
          fieldDAF,
          cTy,
          cTreswastey,
          flareBurningCapacity,
          flareBurningEfficiency,
          fossilFuelConsumptionsForTransportationOperations,
          liquidFermentedFertilizerDryMatterRate,
          ndy,
          tdljy,
          monthlyFeedStockNitrogenConcentrationList
        );

        if (!result.isOk) {
          notify(result.message, "error", 2000);
        } else {
          notify(result.message, "success", 2000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setLoading(false);
      }
    },
    [id, formData]
  );

  const yearlyCarbonEmissionsSelectBoxList = useSelector(
    (state) => state.selectBoxLists.yearlyCarbonEmissionsSelectBoxList
  );
  const biogasPlantInfoSelectBoxList = useSelector(
    (state) => state.selectBoxLists.biogasPlantInfoSelectBoxList
  );
  return (
    <React.Fragment>
      <h2 className={"content-block"}>
        Biogas Plant Survey {isIdEmpty ? "Save" : "Update"} Form
      </h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <form className={"login-form"} onSubmit={onSubmit}>
            <Form formData={formData} disabled={loading} colCount={2}>
              <GroupItem caption="Biogas Plant Survey Infos" colCount={4}>
                <Item
                  colSpan={4}
                  dataField={"yearlyCarbonEmissionsId"}
                  editorType={"dxSelectBox"}
                  editorOptions={{
                    items: yearlyCarbonEmissionsSelectBoxList,
                    displayExpr: "text",
                    valueExpr: "value",
                    stylingMode: "filled",
                    searchEnabled: true,
                    onValueChanged: handleChange("yearlyCarbonEmissionsId"),
                  }}
                >
                  <RequiredRule message="YearlyCarbonEmissions is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "yearlyCarbonEmissionsId",
                        "Yearly CarbonEmissions",
                        "Yearly CarbonEmissions"
                      )
                    }
                  />
                </Item>
                <Item
                  colSpan={4}
                  dataField={"biogasPlantInfoId"}
                  editorType={"dxSelectBox"}
                  editorOptions={{
                    items: biogasPlantInfoSelectBoxList,
                    displayExpr: "text",
                    valueExpr: "value",
                    stylingMode: "filled",
                    searchEnabled: true,
                    onValueChanged: handleChange("biogasPlantInfoId"),
                  }}
                >
                  <RequiredRule message="BiogasPlantInfo is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "biogasPlantInfoId",
                        "Biogas PlantInfo",
                        "Biogas PlantInfo"
                      )
                    }
                  />
                </Item>
                <Item
                  colSpan={2}
                  dataField={"grossElectricityGeneration"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Gross Electricity Generation",
                    format: "##0.####",
                    onValueChanged: handleChange("grossElectricityGeneration"),
                  }}
                >
                  <RequiredRule message="Gross Electricity Generation is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "grossElectricityGeneration",
                        "Gross Electricity Generation",
                        "Gross Electricity Generation"
                      )
                    }
                  />
                </Item>
                <Item
                  colSpan={2}
                  dataField={"netElectricityGeneration"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Net Electricity Generation",
                    format: "##0.####",
                    onValueChanged: handleChange("netElectricityGeneration"),
                  }}
                >
                  <RequiredRule message="Net Electricity Generation is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "netElectricityGeneration",
                        "Net Electricity Generation",
                        "Net Electricity Generation"
                      )
                    }
                  />
                </Item>
                <Item
                  colSpan={2}
                  dataField={"electricityConsumption"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Electricity Consumption",
                    format: "##0.####",
                    onValueChanged: handleChange("electricityConsumption"),
                  }}
                >
                  <RequiredRule message="Electricity Consumption is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "electricityConsumption",
                        "Electricity Consumption",
                        "Electricity Consumption"
                      )
                    }
                  />
                </Item>
                <Item
                  dataField={
                    "fossilFuelConsumptionsWithoutTransportationOperations"
                  }
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder:
                      "Fossil Fuel Consumptions Without Transportation Operations",
                    format: "##0.####",
                    onValueChanged: handleChange(
                      "fossilFuelConsumptionsWithoutTransportationOperations"
                    ),
                  }}
                >
                  <RequiredRule message="Fossil Fuel Consumptions Without Transportation Operations is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "fossilFuelConsumptionsWithoutTransportationOperations",
                        "FFCWTO",
                        "Fossil Fuel Consumptions Without Transportation Operations"
                      )
                    }
                  />
                </Item>
                <Item
                  dataField={
                    "fossilFuelConsumptionsForTransportationOperations"
                  }
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder:
                      "Fossil Fuel Consumptions For Transportation Operations",
                    format: "##0.####",
                    onValueChanged: handleChange(
                      "fossilFuelConsumptionsForTransportationOperations"
                    ),
                  }}
                >
                  <RequiredRule message="Fossil Fuel Consumptions For Transportation Operations is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "fossilFuelConsumptionsForTransportationOperations",
                        "FFCFTO",
                        "Fossil Fuel Consumptions For Transportation Operations"
                      )
                    }
                  />
                </Item>
                <Item colSpan={2}
                  dataField={"flareBurningCapacity"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Flare Burning Capacity",
                    format: "##0.####",
                    onValueChanged: handleChange("flareBurningCapacity"),
                  }}
                >
                  <RequiredRule message="Flare Burning Capacity is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "flareBurningCapacity",
                        "Flare Burning Capacity",
                        "Flare Burning Capacity"
                      )
                    }
                  />
                </Item>
                <Item  colSpan={2}
                  dataField={"flareBurningEfficiency"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Flare Burning Efficiency",
                    format: "##0.####",
                    onValueChanged: handleChange("flareBurningEfficiency"),
                  }}
                >
                  <RequiredRule message="Flare Burning Efficiency is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "flareBurningEfficiency",
                        "Flare Burning Efficiency",
                        "Flare Burning Efficiency"
                      )
                    }
                  />
                </Item>
                <Item colSpan={2}
                  dataField={"biogasProduction"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Biogas Production",
                    format: "##0.####",
                    onValueChanged: handleChange("biogasProduction"),
                  }}
                >
                  <RequiredRule message="Biogas Production is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "biogasProduction",
                        "Biogas Production",
                        "Biogas Production"
                      )
                    }
                  />
                </Item>
                <Item
                  dataField={"biogasMethaneContentAverage"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Biogas Methane Content Average",
                    format: "##0.####",
                    onValueChanged: handleChange("biogasMethaneContentAverage"),
                  }}
                >
                  <RequiredRule message="Biogas Methane Content Average is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "biogasMethaneContentAverage",
                        "BMCA",
                        "Biogas Methane Content Average"
                      )
                    }
                  />
                </Item>
            
                <Item
                  dataField={"cTy"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "CTy",
                    format: "##0.####",
                    onValueChanged: handleChange("cTy"),
                  }}
                >
                  <RequiredRule message="CTy is Required." />
                  <Label
                    render={() => LabelPopoverTemplate("cTy", "CTy", "CTy")}
                  />
                </Item>
                <Item colSpan={2}
                  dataField={"cTreswastey"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "CTreswastey",
                    format: "##0.####",
                    onValueChanged: handleChange("cTreswastey"),
                  }}
                >
                  <RequiredRule message="CTreswastey is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "cTreswastey",
                        "CTreswastey",
                        "CTreswastey"
                      )
                    }
                  />
                </Item>
                <Item colSpan={2}
                  dataField={"methaneProduction"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Methane Production",
                    format: "##0.####",
                    onValueChanged: handleChange("methaneProduction"),
                  }}
                >
                  <RequiredRule message="Methane Production is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "methaneProduction",
                        "Methane Production",
                        "Methane Production"
                      )
                    }
                  />
                </Item>
                <Item
                  dataField={"manureProcessingAnnualAmount"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Manure Processing Annual Amount",
                    format: "##0.####",
                    onValueChanged: handleChange(
                      "manureProcessingAnnualAmount"
                    ),
                  }}
                >
                  <RequiredRule message="Manure Processing Annual Amount is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "manureProcessingAnnualAmount",
                        "MPAA",
                        "Manure Processing Annual Amount"
                      )
                    }
                  />
                </Item>
                <Item
                  dataField={"processedManureTransportedAnnualAmount"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Processed Manure Transported Annual Amount",
                    format: "##0.####",
                    onValueChanged: handleChange(
                      "processedManureTransportedAnnualAmount"
                    ),
                  }}
                >
                  <RequiredRule message="Processed Manure Transported Annual Amount is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "processedManureTransportedAnnualAmount",
                        "PMTAA",
                        "Processed Manure Transported Annual Amount"
                      )
                    }
                  />
                </Item>
                <Item colSpan={2}
                  dataField={"farmLandDAF"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Farm Land DAF",
                    format: "##0.####",
                    onValueChanged: handleChange("farmLandDAF"),
                  }}
                >
                  <RequiredRule message="Farm Land DAF is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "farmLandDAF",
                        "Farm Land DAF",
                        "Farm Land DAF"
                      )
                    }
                  />
                </Item>
                <Item colSpan={2}
                  dataField={"fieldDAF"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Field DAF",
                    format: "##0.####",
                    onValueChanged: handleChange("fieldDAF"),
                  }}
                >
                  <RequiredRule message="Field DAF is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate("fieldDAF", "Field DAF", "Field DAF")
                    }
                  />
                </Item>
                <Item colSpan={2}
                  dataField={"liquidFermentedFertilizerDryMatterRate"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Liquid Fermented Fertilizer Dry Matter Rate",
                    format: "##0.####",
                    onValueChanged: handleChange(
                      "liquidFermentedFertilizerDryMatterRate"
                    ),
                  }}
                >
                  <RequiredRule message="Liquid Fermented Fertilizer Dry Matter Rate is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "liquidFermentedFertilizerDryMatterRate",
                        "LFFDMR",
                        "Liquid Fermented Fertilizer Dry Matter Rate"
                      )
                    }
                  />
                </Item>
                <Item colSpan={2}
                  dataField={"ndy"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Ndy",
                    format: "##0.####",
                    onValueChanged: handleChange("ndy"),
                  }}
                >
                  <RequiredRule message="Ndy is Required." />
                  <Label
                    render={() => LabelPopoverTemplate("ndy", "Ndy", "Ndy")}
                  />
                </Item>
                <Item colSpan={2}
                  dataField={"tdljy"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "Tdljy",
                    format: "##0.####",
                    onValueChanged: handleChange("tdljy"),
                  }}
                >
                  <RequiredRule message="Tdljy is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate("tdljy", "Tdljy", "Tdljy")
                    }
                  />
                </Item>
              </GroupItem>
              <GroupItem
                caption="Monthly Feed Stock Nitrogen Concentration"
                colCount={2}
              >
                <Item
                  dataField={"feedStockNitrogenConcentrationJan"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "January",
                    format: "##0.####",
                    onValueChanged: handleChange(
                      "feedStockNitrogenConcentrationJan"
                    ),
                  }}
                >
                  <RequiredRule message="FeedStock Nitrogen Concentration Jan is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "feedStockNitrogenConcentrationJan",
                        "January",
                        "January"
                      )
                    }
                  />
                </Item>
                <Item
                  dataField={"feedStockNitrogenConcentrationFeb"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "February",
                    format: "##0.####",
                    onValueChanged: handleChange(
                      "feedStockNitrogenConcentrationFeb"
                    ),
                  }}
                >
                  <RequiredRule message="FeedStock Nitrogen Concentration Feb is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "feedStockNitrogenConcentrationFeb",
                        "February",
                        "February"
                      )
                    }
                  />
                </Item>
                <Item
                  dataField={"feedStockNitrogenConcentrationMar"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "March",
                    format: "##0.####",
                    onValueChanged: handleChange(
                      "feedStockNitrogenConcentrationMar"
                    ),
                  }}
                >
                  <RequiredRule message="FeedStock Nitrogen Concentration Mar is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "feedStockNitrogenConcentrationMar",
                        "March",
                        "March"
                      )
                    }
                  />
                </Item>
                <Item
                  dataField={"feedStockNitrogenConcentrationApr"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "April",
                    format: "##0.####",
                    onValueChanged: handleChange(
                      "feedStockNitrogenConcentrationApr"
                    ),
                  }}
                >
                  <RequiredRule message="FeedStock Nitrogen Concentration Apr is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "feedStockNitrogenConcentrationApr",
                        "April",
                        "April"
                      )
                    }
                  />
                </Item>
                <Item
                  dataField={"feedStockNitrogenConcentrationMay"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "May",
                    format: "##0.####",
                    onValueChanged: handleChange(
                      "feedStockNitrogenConcentrationMay"
                    ),
                  }}
                >
                  <RequiredRule message="FeedStock Nitrogen Concentration May is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "feedStockNitrogenConcentrationMay",
                        "May",
                        "May"
                      )
                    }
                  />
                </Item>
                <Item
                  dataField={"feedStockNitrogenConcentrationJun"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "June",
                    format: "##0.####",
                    onValueChanged: handleChange(
                      "feedStockNitrogenConcentrationJun"
                    ),
                  }}
                >
                  <RequiredRule message="FeedStock Nitrogen Concentration Jun is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "feedStockNitrogenConcentrationJun",
                        "June",
                        "June"
                      )
                    }
                  />
                </Item>
                <Item
                  dataField={"feedStockNitrogenConcentrationJul"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "July",
                    format: "##0.####",
                    onValueChanged: handleChange(
                      "feedStockNitrogenConcentrationJul"
                    ),
                  }}
                >
                  <RequiredRule message="FeedStock Nitrogen Concentration Jul is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "feedStockNitrogenConcentrationJul",
                        "July",
                        "July"
                      )
                    }
                  />
                </Item>
                <Item
                  dataField={"feedStockNitrogenConcentrationAug"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "August",
                    format: "##0.####",
                    onValueChanged: handleChange(
                      "feedStockNitrogenConcentrationAug"
                    ),
                  }}
                >
                  <RequiredRule message="FeedStock Nitrogen Concentration Aug is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "feedStockNitrogenConcentrationAug",
                        "August",
                        "August"
                      )
                    }
                  />
                </Item>
                <Item
                  dataField={"feedStockNitrogenConcentrationSep"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "September",
                    format: "##0.####",
                    onValueChanged: handleChange(
                      "feedStockNitrogenConcentrationSep"
                    ),
                  }}
                >
                  <RequiredRule message="FeedStock Nitrogen Concentration Sep is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "feedStockNitrogenConcentrationSep",
                        "September",
                        "September"
                      )
                    }
                  />
                </Item>
                <Item
                  dataField={"feedStockNitrogenConcentrationOct"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "October",
                    format: "##0.####",
                    onValueChanged: handleChange(
                      "feedStockNitrogenConcentrationOct"
                    ),
                  }}
                >
                  <RequiredRule message="FeedStock Nitrogen Concentration Oct is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "feedStockNitrogenConcentrationOct",
                        "October",
                        "October"
                      )
                    }
                  />
                </Item>
                <Item
                  dataField={"feedStockNitrogenConcentrationNov"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "November",
                    format: "##0.####",
                    onValueChanged: handleChange(
                      "feedStockNitrogenConcentrationNov"
                    ),
                  }}
                >
                  <RequiredRule message="FeedStock Nitrogen Concentration Nov is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "feedStockNitrogenConcentrationNov",
                        "November",
                        "November"
                      )
                    }
                  />
                </Item>
                <Item
                  dataField={"feedStockNitrogenConcentrationDec"}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    stylingMode: "filled",
                    placeholder: "December",
                    format: "##0.####",
                    onValueChanged: handleChange(
                      "feedStockNitrogenConcentrationDec"
                    ),
                  }}
                >
                  <RequiredRule message="FeedStock Nitrogen Concentration Dec is Required." />
                  <Label
                    render={() =>
                      LabelPopoverTemplate(
                        "feedStockNitrogenConcentrationDec",
                        "December",
                        "December"
                      )
                    }
                  />
                </Item>
              </GroupItem>
              <ButtonItem colSpan={2}>
                <ButtonOptions
                  width={"100%"}
                  type="normal"
                  stylingMode="outlined"
                  useSubmitBehavior={true}
                >
                  <span className="dx-button-text">
                    {loading ? (
                      <LoadIndicator
                        width={"24px"}
                        height={"24px"}
                        visible={true}
                      />
                    ) : (
                      "Kaydet"
                    )}
                  </span>
                </ButtonOptions>
              </ButtonItem>
            </Form>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}
