import axios from "axios";

export async function save(
  id,
  biogasPlantInfoId,
  yearlyCarbonEmissionsId,
  grossElectricityGeneration,
  netElectricityGeneration,
  electricityConsumption,
  fossilFuelConsumptionsWithoutTransportationOperations,
  biogasProduction,
  biogasMethaneContentAverage,
  methaneProduction,
  manureProcessingAnnualAmount,
  processedManureTransportedAnnualAmount,
  farmLandDAF,
  fieldDAF,
  cTy,
  cTreswastey,
  flareBurningCapacity,
  flareBurningEfficiency,
  fossilFuelConsumptionsForTransportationOperations,
  liquidFermentedFertilizerDryMatterRate,
  ndy,
  tdljy
) {
  try {
    var res = await axios.post(
      `${process.env.REACT_APP_API_URL}/biogasPlantSurvey/save`,
      {
        id,
        biogasPlantInfoId,
        yearlyCarbonEmissionsId,
        grossElectricityGeneration,
        netElectricityGeneration,
        electricityConsumption,
        fossilFuelConsumptionsWithoutTransportationOperations,
        biogasProduction,
        biogasMethaneContentAverage,
        methaneProduction,
        manureProcessingAnnualAmount,
        processedManureTransportedAnnualAmount,
        farmLandDAF,
        fieldDAF,
        cTy,
        cTreswastey,
        flareBurningCapacity,
        flareBurningEfficiency,
        fossilFuelConsumptionsForTransportationOperations,
        liquidFermentedFertilizerDryMatterRate,
        ndy,
        tdljy
      }
    );

    return {
      isOk: true,
      data: res.data.data,
      message: res.data.message,
    };
  } catch (error) {
    console.log(res);
    return {
      isOk: false,
      message: "Kayıt işlemi başarısız.",
    };
  }
}

export async function getById(id) {
  try {
    var res = await axios.get(
      `${process.env.REACT_APP_API_URL}/biogasPlantSurvey/getbyid?id=${id}`
    );

    return {
      isOk: true,
      data: res.data,
    };
  } catch (error) {
    return {
      isOk: false,
      message: "İşlem başarısız.",
    };
  }
}
