import React from "react";
import {
  Export,
  Column,
  DataGrid,
  FilterRow,
  SearchPanel,
  Button,
  HeaderFilter,
  Paging,
} from "devextreme-react/data-grid";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { useNavigate } from "react-router-dom";
import {userDataStore} from '../../api/dxDataStores'
export default function Users() {
  const navigate = useNavigate();
 
  function onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("User");

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "UserList.xlsx"
        );
      });
    });
    e.cancel = true;
  }
  const onEditClick = (e) => {
    // Seçili satırın ID'sini kullanarak edit sayfasına yönlendirme işlemini gerçekleştir
    navigate(`/preferences/users/form/${e.row.data.id}`);
  };
  const onDeleteClick = (e) => {
    // Seçili satırın ID'sini kullanarak edit sayfasına yönlendirme işlemini gerçekleştir
    console.log("Silme İşlemi");
  };
  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <h2 className={"content-block"}>Users</h2>
          <DataGrid
            dataSource={userDataStore}
            showBorders={true}
              height={700}
              remoteOperations={true}
              onExporting={onExporting}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
          >
            <SearchPanel visible={true} highlightCaseSensitive={true} />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
                   
            <Paging defaultPageSize={15} />   
            <Column caption="" type="buttons">
              <Button hint="Edit" icon="edit" onClick={onEditClick} />
              <Button hint="Delete" icon="trash" onClick={onDeleteClick} />
            </Column>

            <Column dataField="firstName" caption="First Name" />
            <Column dataField="lastName" caption="Last Name" />
            <Export enabled={true} allowExportSelectedData={true} />
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
  );
}
