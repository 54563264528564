import React from "react";
import {
  Export,
  Item,
  Toolbar,
  ColumnChooser,
  Column,
  Button,Lookup,
  DataGrid,
  FilterRow,
  SearchPanel,
  HeaderFilter,
  Paging,
} from "devextreme-react/data-grid";
import { Button as DxButton } from "devextreme-react/button";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { useNavigate } from "react-router-dom";
import { digesterLiquidWasteAnalysisDataStore,digesterDataStore } from "../../api/dxDataStores";
import { handleDxExcelExport} from "../../utils/dxExcelExportUtils"; // Dosya yolu doğru ise ayarlayın

export default function DigesterLiquidWasteAnalysis() {
  const navigate = useNavigate();
  function onExporting(e) {
    handleDxExcelExport(e, "DigesterLiquidWasteAnalysis", "DigesterLiquidWasteAnalysis.xlsx");
  }

  const onEditClick = (e) => {
    navigate(`/qualityControl/digesterLiquidWasteAnalysis/form/${e.row.data.id}`);
  };
  const onDeleteClick = (e) => {
    console.log("Silme İşlemi");
  };
  const onAddClick = (e) => {
    navigate(`/qualityControl/digesterLiquidWasteAnalysis/form/${"00000000-0000-0000-0000-000000000000"}`);
  };
  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <h2 className={"content-block"}>Digester Liquid Waste Analysis</h2>
          <DataGrid
            dataSource={digesterLiquidWasteAnalysisDataStore}
            showBorders={true}
            height={700}
            remoteOperations={true}
            onExporting={onExporting}
            columnAutoWidth={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
          >
            <Toolbar>
              <Item location="after" locateInMenu="auto">
                <DxButton icon="plus" onClick={onAddClick} />
              </Item>
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="exportButton" locateInMenu="auto" />
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="columnChooserButton" locateInMenu="auto" />
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="searchPanel" location="after" />
            </Toolbar>
            <ColumnChooser enabled />
            <SearchPanel
              visible={true}
              highlightCaseSensitive={true}
              placeholder="Search"
              width={250}
            />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Paging defaultPageSize={15} />
            <Column type="buttons">
              <Button hint="Edit" icon="edit" onClick={onEditClick} />
              <Button hint="Delete" icon="trash" onClick={onDeleteClick} />
            </Column>
            <Column dataField="digesterId" caption="Digester Name">
              <Lookup
                dataSource={digesterDataStore}
                valueExpr="id"
                displayExpr="name"
              />            
            </Column>
            <Column
              dataField="entryTime"
              caption="Entry Time"
              dataType="datetime"
              format="dd.MM.yyyy HH:mm"
            />
            <Column
              dataField="krozeDefinition"
              caption="Kroze Definition"
              dataType="text"             
            />
            <Column
              dataField="krozeEmptyWeight"
              caption="Kroze Empty Weight"
              dataType="number"
              format={"##0.####"}
            />
            <Column
              dataField="krozeFullWeight"
              caption="Kroze Full Weight"
              dataType="number"
              format={"##0.####"}
            />
            <Column
              dataField="c105"
              caption="C105"
              dataType="number"
              format={"##0.####"}
            />
             <Column
              dataField="c550"
              caption="C550"
              dataType="number"
              format={"##0.####"}
            />
             <Column
              dataField="ph"
              caption="PH"
              dataType="number"
              format={"##0.####"}
            />
             <Column
              dataField="ec"
              caption="EC"
              dataType="number"
              format={"##0.####"}
            />
            <Export enabled={true} allowExportSelectedData={true} />
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
  );
}
