import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import "./yearlyCarbonEmissionDetail.scss";
import notify from "devextreme/ui/notify";
import { getDetailById } from "../../../api/yearlyCarbonEmissions";
import { useDispatch } from "react-redux";
import YearlyCarbonEmissionDetailTask from './yearlyCarbonEmissionDetailTask';
export default function YearlyCarbonEmissionsDetail() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isEmptyGuid = (guid) => guid === "00000000-0000-0000-0000-000000000000";

  const [detailData, setDetailData] = useState([]);

  const [loading, setLoading] = useState(false);
  const isIdEmpty = isEmptyGuid(id);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getDetailById(id);
      if (result.isOk) {
        setDetailData(result.data);
      } else {
        notify(result.message, "error", 2000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (!isIdEmpty) {
      fetchData();
    }
  }, [fetchData, isIdEmpty]);



  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <h2 className={"content-block"}>
            Yearly Carbon Emission Detail
          </h2>
          <div className={"dx-card responsive-paddings"}>
            <div className="tasks">
              {detailData.map((data, index) => (
                <YearlyCarbonEmissionDetailTask
                  yearlyCarbonEmissionsId={data.yearlyCarbonEmissionsId}
                  taskId={data.taskId}
                  title={data.title}
                  isCompleted={data.isCompleted}
                  taskLinks={data.taskLinks} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
