import React, { useState, useEffect } from "react";
import {
  Chart,
  Series,
  ArgumentAxis,
  CommonSeriesSettings,
  CommonAxisSettings,
  Grid,
  Export,
  Legend,
  Margin,
  Tooltip,
  Label,
  Format,
} from "devextreme-react/chart";
import { getWeighbridgeChartData } from "../../api/weighbridgeData";
export default function WeighbridgeDataLineChart() {

  const [weighbridgeChartData, setWeighbridgeChartData] = useState([]);
  useEffect(() => {
    async function fetchData() {
        const res = await getWeighbridgeChartData();
      if (res.isOk) setWeighbridgeChartData(res.data);
    }
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Chart
        palette="Violet"
        dataSource={weighbridgeChartData}
        title="Daily Weighbridge Datas (Last 1 Month)"
      >
        <CommonSeriesSettings argumentField="day" type={"spline"} />
        <CommonAxisSettings>
          <Grid visible={true} />
        </CommonAxisSettings>
        <Series key={"tonnage"} valueField={"tonnage"} name={"Tonnage"} />
        <Margin bottom={20} />
        <ArgumentAxis allowDecimals={false} axisDivisionFactor={60}>
          <Label>
            <Format type="decimal" />
          </Label>
        </ArgumentAxis>
        <Legend verticalAlignment="top" horizontalAlignment="right" />
        <Export enabled={true} />
        <Tooltip enabled={true} />
      </Chart>
    </React.Fragment>
  );
}
