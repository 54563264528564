import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "../slices/global-slice";
import authReducer from "../slices/auth-slice";
import userReducer from "../slices/user-slice";
import selectBoxListsReducer from "../slices/selectBoxLists-slice";

export const store = configureStore({
  reducer: {
    global: globalReducer,
    auth: authReducer,
    user: userReducer,
    selectBoxLists: selectBoxListsReducer,
  },
});

export const AppDispatch = store.dispatch;
export const RootState = store.getState;
