import React, { useState } from "react";
import {
  Export,
  Item,
  Toolbar,
  ColumnChooser,
  Column,
  Button,
  DataGrid,
  FilterRow,
  SearchPanel,
  HeaderFilter,
  Paging,
} from "devextreme-react/data-grid";
import { Button as DxButton } from "devextreme-react/button";
import { useNavigate } from "react-router-dom";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import { yearlyCarbonEmissionsDataStore } from "../../api/dxDataStores";
import { handleDxExcelExport } from "../../utils/dxExcelExportUtils"; // Dosya yolu doğru ise ayarlayın

export default function WeighbridgeDatas() {
  const navigate = useNavigate();
  function onExporting(e) {
    handleDxExcelExport(e, "YearlyCarbonEmission", "YearlyCarbonEmissions.xlsx");
  }
 

  const onEditClick = (e) => {
    navigate(`/carbonEmissionTracking/yearlyCarbonEmissions/form/${e.row.data.id}`);
  };
  const onDetailClick = (e) => {
    navigate(`/carbonEmissionTracking/yearlyCarbonEmissions/detail/${e.row.data.id}`);
  };
  const onDeleteClick = (e) => {
    console.log("Silme İşlemi");
    setVisible(true);
  };
  const onAddClick = (e) => {
    navigate(
      `/carbonEmissionTracking/yearlyCarbonEmissions/form/${"00000000-0000-0000-0000-000000000000"}`
    );
  };
  const [visible, setVisible] = useState(false);

  const hidePopup = () => {
    setVisible(false);
  };
  return (
    <>
      <React.Fragment>
        <div className={"content-block"}>
          <div className={"dx-card responsive-paddings"}>
            <h2 className={"content-block"}>Weighbridge Datas</h2>
            <DataGrid
              dataSource={yearlyCarbonEmissionsDataStore}
              showBorders={true}
              height={700}
              remoteOperations={true}
              onExporting={onExporting}
              columnAutoWidth={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
            >
              <Toolbar>
                <Item location="after" locateInMenu="auto">
                  <DxButton icon="plus" onClick={onAddClick} />
                </Item>
                <Item location="after" locateInMenu="auto">
                  <div className="separator" />
                </Item>
                <Item name="exportButton" locateInMenu="auto" />
                <Item location="after" locateInMenu="auto">
                  <div className="separator" />
                </Item>
                <Item name="columnChooserButton" locateInMenu="auto" />
                <Item location="after" locateInMenu="auto">
                  <div className="separator" />
                </Item>
                <Item name="searchPanel" location="after" />
              </Toolbar>
              <ColumnChooser enabled />
              <SearchPanel
                visible={true}
                highlightCaseSensitive={true}
                placeholder="Search"
                width={250}
              />
              <FilterRow visible={true} />
              <HeaderFilter visible={true} />
              <Paging defaultPageSize={15} />
              <Column type="buttons">
                <Button hint="Edit" icon="edit" onClick={onEditClick} />
                <Button hint="Detail" icon="info" onClick={onDetailClick} />
                <Button hint="Delete" icon="trash" onClick={onDeleteClick} />
              </Column>

              <Column dataField="title" caption="Title" />
              <Column dataField="year" caption="Year" dataType="text" />

              <Export enabled={true} allowExportSelectedData={true} />
            </DataGrid>
          </div>
        </div>
      </React.Fragment>
      <Popup
        visible={visible}
        onHiding={hidePopup}
        dragEnabled={true}
        showTitle={true}
        title="Sample Popup"
      >
        <ToolbarItem
          widget="dxButton"
          toolbar="top"
          location="after"
          options={{ icon: "close", onClick: hidePopup }}
        />
      </Popup>
    </>
  );
}
