import React from "react";
import { Tooltip } from "devextreme-react/tooltip";

function LabelPopoverTemplate(id,text, tip) {
  const helpedInfoId=`helpedInfo_${id}`
  const helpedInfoTarget=`#helpedInfo_${id}`
  return (
    <React.Fragment>
      <div  id={helpedInfoId} className="dx-field-item-label-text">     
        {text}
      </div>

      <Tooltip
        target={helpedInfoTarget}
        showEvent="mouseenter"
        hideEvent="mouseleave"
      >
        <div id="tooltip-content">{tip}</div>
      </Tooltip>
    </React.Fragment>
  );
}
export default LabelPopoverTemplate;
