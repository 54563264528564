import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import 'devextreme/dist/css/dx.material.teal.dark.css';
import React from "react";
import { HashRouter as Router } from "react-router-dom";
import "../src/themes/generated/dx-styles.scss";
import { NavigationProvider } from "./contexts/navigation";
import { useScreenSizeClass } from "./utils/media-query";
import Content from "./Content";
import UnauthenticatedContent from "./UnauthenticatedContent";
import { useSelector } from "react-redux";
function App() {
  const authToken = useSelector((state) => state.auth.token);
  const isAuthorized = authToken.token === "" ? false : true;
  if (isAuthorized) {
    return <Content />;
  }
  return <UnauthenticatedContent />;
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>     
        <NavigationProvider>
          <div className={`app ${screenSizeClass}`}>
            <App />
          </div>
        </NavigationProvider>     
    </Router>
  );
}
