import React from "react";
import {
  Export,
  Item,
  Toolbar,
  ColumnChooser,
  Column,
  Button,
  DataGrid,
  FilterRow,
  SearchPanel,
  HeaderFilter,
  Paging,
} from "devextreme-react/data-grid";
import { Button as DxButton } from "devextreme-react/button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { farmLivestockTypeDataStore } from "../../api/dxDataStores";
import { handleDxExcelExport } from "../../utils/dxExcelExportUtils"; // Dosya yolu doğru ise ayarlayın

export default function FarmLivestockTypes() {
  const navigate = useNavigate();
  const livestockTypeSelectBoxList = useSelector(
    (state) => state.selectBoxLists.livestockTypeSelectBoxList
  );
  const farmLivestockBreedOriginSelectBoxList = useSelector(
    (state) => state.selectBoxLists.farmLivestockBreedOriginSelectBoxList
  );
  function onExporting(e) {
    handleDxExcelExport(e, "FarmLivestockType", "FarmLivestockTypes.xlsx");
  }
 
  const onEditClick = (e) => {
    navigate(`/rawMaterialManagement/farmLivestockTypes/form/${e.row.data.id}`);
  };
  const onDeleteClick = (e) => {
    console.log("Silme İşlemi");
  };
  const onAddClick = (e) => {
    navigate(
      `/rawMaterialManagement/farmLivestockTypes/form/${"00000000-0000-0000-0000-000000000000"}`
    );
  };

  return (
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <h2 className={"content-block"}>Farm Livestock Types</h2>
          <DataGrid
            dataSource={farmLivestockTypeDataStore}
            showBorders={true}
            height={700}
            remoteOperations={true}
            onExporting={onExporting}
            columnAutoWidth={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
          >
            <Toolbar>
              <Item location="after" locateInMenu="auto">
                <DxButton icon="plus" onClick={onAddClick} />
              </Item>
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="exportButton" locateInMenu="auto" />
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="columnChooserButton" locateInMenu="auto" />
              <Item location="after" locateInMenu="auto">
                <div className="separator" />
              </Item>
              <Item name="searchPanel" location="after" />
            </Toolbar>
            <ColumnChooser enabled />
            <SearchPanel
              visible={true}
              highlightCaseSensitive={true}
              placeholder="Search"
              width={250}
            />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Paging defaultPageSize={15} />
            <Column type="buttons">
              <Button hint="Edit" icon="edit" onClick={onEditClick} />
              <Button hint="Delete" icon="trash" onClick={onDeleteClick} />
            </Column>
            <Column
              dataField="livestockType"
              caption="Livestock Type"
              calculateCellValue={(data) => {
                return livestockTypeSelectBoxList[data.livestockType].value;
              }}
            />
            <Column dataField="livestockBreed" caption="Livestock Breed" />
            <Column
              dataField="farmLivestockBreedOrigin"
              caption="Farm Livestock Breed Origin"
              calculateCellValue={(data) => {
                return farmLivestockBreedOriginSelectBoxList[
                  data.farmLivestockBreedOrigin
                ].value;
              }}
            />
            <Column dataField="wd" caption="WD" />
            <Column dataField="b0LT" caption="B0LT" />
            <Column dataField="vs" caption="VS" />

            <Export enabled={true} allowExportSelectedData={true} />
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
  );
}
