import {
  HomePage,
  UsersPage,
  UserFormPage,
  BEyAMCalc,
  FarmSurveysPage,
  FarmSurveyFormPage,
  BiogasPlantSurveysPage,
  BiogasPlantSurveyFormPage,
  WeighbridgeDatasPage,
  WeighbridgeDataFormPage,
  FarmLivestockTypesPage,
  FarmLivestockTypeFormPage,
  FarmsPage,
  FarmFormPage,
  BiogasPlantInfosPage,
  BiogasPlantInfoFormPage,
  YearlyCarbonEmissionsPage,
  YearlyCarbonEmissionsFormPage,
  YearlyCarbonEmissionsDetailPage,
  DigesterDataFormPage,
  OsosDataFormPage,
  DigesterLiquidWasteAnalysisFormPage,
  FosTacAnalysisFormPage,
  LiquidWasteAnalysisFormPage,
  SolidWasteAnalysisFormPage,
  LiquidWasteInputFormPage,
  SolidWasteInputFormPage,
  ProcessReinforcementDataFormPage,
  ProcessReinforcementMaterialFormPage,
  DigesterFormPage,
  DigesterDatasPage,
  OsosDatasPage,
  DigesterLiquidWasteAnalysisPage,
  FosTacAnalysisPage,
  LiquidWasteAnalysisPage,
  SolidWasteAnalysisPage,
  LiquidWasteInputsPage,
  SolidWasteInputsPage,
  ProcessReinforcementDatasPage,
  ProcessReinforcementMaterialsPage,
  DigestersPage,
  LiquidFermentedFertilizerWeightbridgeDataPage,
  LiquidFermentedFertilizerWeightbridgeDataFormPage,
} from "./pages";
import { withNavigationWatcher } from "./contexts/navigation";

const routes = [
  {
    path: "/home",
    element: HomePage,
  },
  {
    path: "/rawMaterialManagement/farms",
    element: FarmsPage,
  },
  {
    path: "/rawMaterialManagement/farms/form/:id",
    element: FarmFormPage,
  },
  {
    path: "/rawMaterialManagement/farmLivestockTypes",
    element: FarmLivestockTypesPage,
  },
  {
    path: "/rawMaterialManagement/farmLivestockTypes/form/:id",
    element: FarmLivestockTypeFormPage,
  },

  {
    path: "/inventoryManagement/liquidWasteInputs",
    element: LiquidWasteInputsPage,
  },
  {
    path: "/inventoryManagement/liquidWasteInputs/form/:id",
    element: LiquidWasteInputFormPage,
  },
  {
    path: "/inventoryManagement/solidWasteInputs",
    element: SolidWasteInputsPage,
  },
  {
    path: "/inventoryManagement/solidWasteInputs/form/:id",
    element: SolidWasteInputFormPage,
  },
  {
    path: "/logisticsManagement/weighbridgeDatas",
    element: WeighbridgeDatasPage,
  },
  {
    path: "/logisticsManagement/weighbridgeDatas/form/:id",
    element: WeighbridgeDataFormPage,
  },
  {
    path: "/logisticsManagement/liquidFermentedFertilizerWeightbridgeDatas",
    element: LiquidFermentedFertilizerWeightbridgeDataPage,
  },
  {
    path: "/logisticsManagement/liquidFermentedFertilizerWeightbridgeDatas/form/:id",
    element: LiquidFermentedFertilizerWeightbridgeDataFormPage,
  },
  { path: "/processManagement/digesterDatas", element: DigesterDatasPage },
  {
    path: "/processManagement/digesterDatas/form/:id",
    element: DigesterDataFormPage,
  },
  { path: "/processManagement/digesters", element: DigestersPage },
  { path: "/processManagement/digesters/form/:id", element: DigesterFormPage },
  { path: "/processManagement/ososDatas", element: OsosDatasPage },
  { path: "/processManagement/ososDatas/form/:id", element: OsosDataFormPage },
  {
    path: "/processManagement/processReinforcementDatas",
    element: ProcessReinforcementDatasPage,
  },
  {
    path: "/processManagement/processReinforcementDatas/form/:id",
    element: ProcessReinforcementDataFormPage,
  },
  {
    path: "/processManagement/processReinforcementMaterials",
    element: ProcessReinforcementMaterialsPage,
  },
  {
    path: "/processManagement/processReinforcementMaterials/form/:id",
    element: ProcessReinforcementMaterialFormPage,
  },
  {
    path: "/qualityControl/digesterLiquidWasteAnalysis",
    element: DigesterLiquidWasteAnalysisPage,
  },
  {
    path: "/qualityControl/digesterLiquidWasteAnalysis/form/:id",
    element: DigesterLiquidWasteAnalysisFormPage,
  },

  { path: "/qualityControl/fosTacAnalysis", element: FosTacAnalysisPage },
  {
    path: "/qualityControl/fosTacAnalysis/form/:id",
    element: FosTacAnalysisFormPage,
  },
  {
    path: "/qualityControl/liquidWasteAnalysis",
    element: LiquidWasteAnalysisPage,
  },
  {
    path: "/qualityControl/liquidWasteAnalysis/form/:id",
    element: LiquidWasteAnalysisFormPage,
  },
  {
    path: "/qualityControl/solidWasteAnalysis",
    element: SolidWasteAnalysisPage,
  },
  {
    path: "/qualityControl/solidWasteAnalysis/form/:id",
    element: SolidWasteAnalysisFormPage,
  },
  {
    path: "/carbonEmissionTracking/yearlyCarbonEmissions",
    element: YearlyCarbonEmissionsPage,
  },
  {
    path: "/carbonEmissionTracking/yearlyCarbonEmissions/form/:id",
    element: YearlyCarbonEmissionsFormPage,
  },
  {
    path: "/carbonEmissionTracking/yearlyCarbonEmissions/detail/:id",
    element: YearlyCarbonEmissionsDetailPage,
  },
  {
    path: "/carbonEmissionTracking/biogasPlantInfos",
    element: BiogasPlantInfosPage,
  },
  {
    path: "/carbonEmissionTracking/biogasPlantInfos/form/:id",
    element: BiogasPlantInfoFormPage,
  },
  {
    path: "/carbonEmissionTracking/biogasPlantSurveys",
    element: BiogasPlantSurveysPage,
  },

  {
    path: "/carbonEmissionTracking/biogasPlantSurveys/form/:id",
    element: BiogasPlantSurveyFormPage,
  },
  {
    path: "/carbonEmissionTracking/farmSurveys",
    element: FarmSurveysPage,
  },
  {
    path: "/carbonEmissionTracking/farmSurveys/form/:id",
    element: FarmSurveyFormPage,
  },
  {
    path: "/carbonEmissionTracking/bEyAMCalc",
    element: BEyAMCalc,
  },
  {
    path: "/preferences/users",
    element: UsersPage,
  },
  {
    path: "/preferences/users/form/:id",
    element: UserFormPage,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
