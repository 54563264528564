export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      console.log("nası tak diye burdayım saniyede");      
      const authToken = store.getState().auth.token.token;
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}